import { Toast, ToastContainer } from "react-bootstrap";

export const RiskFreeFlipNotificationToast = ({ show, onClose }: any) => {
  return (
    <ToastContainer position="bottom-start" className="p-3 position-fixed">
      <Toast onClose={onClose} show={show} delay={7000} autohide className="w-100">
        <Toast.Body className="border-0 rounded-1 d-inline-flex">
          <img 
            src="https://i.imgur.com/JCQ9tIK.png" 
            className="img-fluid rounded-sm ms-1 my-1 me-2 image-xs" 
            alt="COIN" 
            style={{ maxWidth: '18px', maxHeight: '18px' }} />
          <div className="text-gray my-1 me-1">
            Lucky You, A Risk Free Flip Awaits.
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useInterval } from "../hooks/useInterval";
import { loadState, saveState } from "../utils/localStorage";
import { PromoEventModal } from "./modals/PromoEventModal";
import { getScroll } from "../api/scroll.service";

const SLOW_INTERVAL = 15000;
const now = DateTime.utc();
const MODAL_KEY = `${now.month}-${now.day}_SCROLL`;
const ScrollStatus = {
    CREATED: 'CREATED'
};

export const ScrollCounter = ({ walletId, authToken, promo: { startTime, promoId, communityId }, style }: any) => {
    const [fragments, setFragments] = useState<number>(0);
    const [cache, setCache] = useState(null);
    const [showJackpotModal, setShowJackpotModal] = useState(false);
    const handleJackpotModalOpen = () => setShowJackpotModal(true);
    const handleJackpotModalClose = () => setShowJackpotModal(false);

    useEffect(() => {
        if (walletId && walletId !== cache) {
            setCache(walletId);
            fetchPromo();
            if (!loadState(MODAL_KEY) && DateTime.fromISO(startTime, { zone: 'UTC' }) < now) {
                setShowJackpotModal(true);
                saveState(true, MODAL_KEY);
            }
        }
        // eslint-disable-next-line
    }, [walletId, cache]);

    useInterval(async () => {
        if (walletId) fetchPromo();
    }, SLOW_INTERVAL);

    const fetchPromo = async () => {
        const scrolls = await getScroll(authToken, communityId, walletId);
        setFragments(scrolls.filter((s: any) => s.status === ScrollStatus.CREATED).length);
    }

    return (
        <>
            {
                showJackpotModal &&
                <PromoEventModal
                    show={showJackpotModal}
                    walletId={walletId}
                    styleCss={style}
                    onHide={() => handleJackpotModalClose()}
                />
            }
            {
                (fragments || fragments === 0) &&
                <h5 onClick={handleJackpotModalOpen} className={"mt-1 balance-text mb-0 cursor-pointer" + (isMobile ? " me-1" : "")}>
                    {fragments}
                    <img src="https://i.imgur.com/HuabjcY.png" className="img-fluid ms-1" alt="fragments" style={{ maxWidth: '20px' }} />
                </h5>
            }
        </>
    );
};

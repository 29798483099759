import { Link } from "react-router-dom";
import CoinFlipWizardVirtualStepOneButtons from "./CoinFlipWizardVirtualStepOneButtons";

const CoinFlipWizardVirtualStepOne = ({
    community,
    restricted,
    side,
    setSide,
    onFlip
}: any) => {

    return (
        <div
            className="play step1 py-4 py-md-0">
            <div className="form-signin">
                <CoinFlipWizardVirtualStepOneButtons
                    side={side}
                    setSide={setSide}
                    onFlip={onFlip} />
                {
                    !restricted &&
                    <h6 className="mt-3 text-secondary">
                        <Link to={`/${community?.slug === "wagmi" ? "?gl=hf" : `${community?.slug ?? ""}?gl=hf`}`} className="ms-sm-2 ms-1 no-decoration">
                            DOUBLE OR NOTHING
                        </Link>
                    </h6>
                }
                {
                    restricted &&
                    <h6 className="mt-3 text-secondary"><a href="/">GO HOME</a></h6>
                }
            </div>
        </div>
    );
};

export default CoinFlipWizardVirtualStepOne;
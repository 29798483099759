import { AddressLookupTableAccount, LAMPORTS_PER_SOL, PublicKey, Transaction, TransactionInstruction, VersionedTransaction } from "@solana/web3.js";
import * as jupiterQuoteApi from "../api/jup.service";
import { customRound } from "../components/side-bets/helpers";
import { JUP_AMT_BUFFER, MINTS } from "../utils/constants";
import { shouldVersionTransaction } from "../utils/helpers";
import { getSolanaProvider, ProviderTypes } from "./solana";



export const fetchQuote = async (amount, inputMint = MINTS.WIF, outputMint = MINTS.SOL) => {
  try {
    const quote = await jupiterQuoteApi.quoteGet({
      inputMint,
      outputMint,
      amount: customRound(amount * (inputMint === MINTS.SOL ? LAMPORTS_PER_SOL : JUP_AMT_BUFFER)),
      maxAccounts: 32,
      onlyDirectRoutes: true,
      asLegacyTransaction: false,
      slippageBps: 50,
      // dexes: [
      //   'Balansol',
      //   'Whirlpool',
      //   'Orca V2',
      //   'GooseFX',
      //   'Oasis',
      //   'Marinade',
      //   'Mercurial',
      //   'Invariant',
      //   'Saros',
      //   'Raydium CLMM',
      //   'Lifinity V1',
      //   'Meteora',
      //   'Orca V1',
      //   'Cropper',
      //   'Helium Network',
      //   'Bonkswap',
      //   'Lifinity V2',
      //   'FluxBeam',
      //   'Token Swap',
      //   'Sanctum',
      //   'Saber (Decimals)',
      //   'Raydium',
      //   'Crema',
      //   'Aldrin V2',
      //   'Jupiter LO',
      //   'Penguin',
      //   'Aldrin',
      //   'StepN',
      //   'Meteora DLMM',
      //   'Phoenix',
      //   'Openbook',
      //   'Saber',
      //   'Perps'
      // ]
    });
    return quote;
  }
  catch (e) {
    console.log(e);
  }

  return null;
}



export const executeClaimTx = async (wallet, amount, outputMint, inputMint = MINTS.SOL) => {
  const provider = getSolanaProvider(wallet, ProviderTypes.PRIMARY);
  const quote = await jupiterQuoteApi.quoteGet({
    outputMint,
    inputMint,
    amount: customRound(amount * (inputMint === MINTS.SOL ? LAMPORTS_PER_SOL : JUP_AMT_BUFFER)),
    maxAccounts: 50,
    // onlyDirectRoutes: true
  });

  // get serialized transaction
  const swapResult = await jupiterQuoteApi.swapPost({
    swapRequest: {
      quoteResponse: quote,
      userPublicKey: wallet?.publicKey?.toString() ?? '',
      dynamicComputeUnitLimit: true,
    },
  });

  const swapTransactionBuf = Buffer.from(swapResult.swapTransaction, "base64");
  var versionedTransaction = VersionedTransaction.deserialize(swapTransactionBuf);

  // sign the transaction

  let tx;
  if (shouldVersionTransaction()) {
    const txes = await provider.sendAll([{ tx: versionedTransaction }]);
    tx = txes[0];
  } else {
    const serialized = versionedTransaction.serialize();
    const x = Transaction.from(serialized);
    tx = await provider.send(x);
  }
  return {
    solAmount: +quote?.outAmount / LAMPORTS_PER_SOL,
    signature: tx
  };
}

export const generateSwapAndFlipTx = async (wallet, quote, priorityFee) => {
  const provider = getSolanaProvider(wallet, ProviderTypes.PRIMARY);
  const { connection } = provider;
  const instructions = await jupiterQuoteApi.swapInstructionsPost({
    swapRequest: {
      quoteResponse: quote,
      userPublicKey: wallet?.publicKey?.toString() ?? '',
      dynamicComputeUnitLimit: true,
      prioritizationFeeLamports: 'auto',
      // useTokenLedger: true,
      onlyDirectRoutes: true,
      // restrictIntermediateTokens: true
      skipUserAccountsRpcCalls: false,
      asLegacyTransaction: false
    },
  });
  if (instructions.error) {
    throw new Error("Failed to get swap instructions: " + instructions.error);
  }
  const {
    setupInstructions, // Setup missing ATA for the users.
    swapInstruction: swapInstructionPayload, // The actual swap instruction.
    cleanupInstruction, // Unwrap the SOL if `wrapAndUnwrapSol = true`.
    addressLookupTableAddresses, // The lookup table addresses that you can use if you are using versioned transaction.
  } = instructions;

  const deserializeInstruction = (instruction) => {
    return new TransactionInstruction({
      programId: new PublicKey(instruction.programId),
      keys: instruction.accounts.map((key) => ({
        pubkey: new PublicKey(key.pubkey),
        isSigner: key.isSigner,
        isWritable: key.isWritable,
      })),
      data: Buffer.from(instruction.data, "base64"),
    });
  };

  const getAddressLookupTableAccounts = async (
    keys
  ) => {
    const addressLookupTableAccountInfos =
      await connection.getMultipleAccountsInfo(
        keys.map((key) => new PublicKey(key))
      );

    return addressLookupTableAccountInfos.reduce((acc, accountInfo, index) => {
      const addressLookupTableAddress = keys[index];
      if (accountInfo) {
        const addressLookupTableAccount = new AddressLookupTableAccount({
          key: new PublicKey(addressLookupTableAddress),
          state: AddressLookupTableAccount.deserialize(accountInfo.data),
        });
        acc.push(addressLookupTableAccount);
      }

      return acc;
    }, []);
  };

  const addressLookupTableAccounts = [];

  addressLookupTableAccounts.push(
    ...(await getAddressLookupTableAccounts(addressLookupTableAddresses))
  );

  return {
    addressLookupTableAccounts,
    swapIxes: [
      // memoIx,
      ...setupInstructions.map(deserializeInstruction),
      deserializeInstruction(swapInstructionPayload),
      deserializeInstruction(cleanupInstruction),
      // gameIx,
    ]
  };

  // let txInstructions;
  // if (isClaim) {
  //   txInstructions = [
  //     gameIx,
  //     ...setupInstructions.map(deserializeInstruction),
  //     deserializeInstruction(swapInstructionPayload),
  //     deserializeInstruction(cleanupInstruction),
  //   ]
  // } else {
  //   txInstructions = [
  //     memoIx,
  //     ...setupInstructions.map(deserializeInstruction),
  //     deserializeInstruction(swapInstructionPayload),
  //     deserializeInstruction(cleanupInstruction),
  //     gameIx,
  //   ]
  // }

  // console.log(txInstructions);

  // const blockhash = (await connection.getLatestBlockhash()).blockhash;
  // const messageV0 = new TransactionMessage({
  //   payerKey: wallet?.publicKey,
  //   recentBlockhash: blockhash,
  //   instructions: txInstructions,
  // }).compileToV0Message(addressLookupTableAccounts);
  // const transaction = new VersionedTransaction(messageV0);
  // console.log(transaction);
  // return transaction;
}
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { activateRiskFreePlay, deactivateRiskFreePlay } from "../../api/aegis.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { RiskFreePlaysContext } from "../../contexts/risk-free-plays.context";
import { RiskFreePlaysStatus } from "../../utils/constants";

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const now = DateTime.utc();

export function RiskFreeFlipModal({ styleCss, riskFreeFlips, ...props }) {
    const { auth } = useContext(AuthorizationContext);
    const { getRiskFreePlays } = useContext(RiskFreePlaysContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getRiskFreePlays();
    // eslint-disable-next-line
    }, []);

    const toggleRiskFreeFlip = async (rff) => {
        setLoading(true);
        if (rff.status === RiskFreePlaysStatus.AVAILABLE) {
            await activateRiskFreePlay(rff?.id, auth?.idToken);
        } else if (rff.status === RiskFreePlaysStatus.ACTIVATED) {
            await deactivateRiskFreePlay(rff?.id, auth?.idToken);
        }
        await getRiskFreePlays(auth?.idToken);
        setLoading(false);
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '484px', overflow: 'scroll' }}>

                        {loading && (
                            <div className="my-5 py-5">
                                <div className="cssload-container py-5 py-lg-0">
                                    <div className="cssload-zenith"></div>
                                </div>
                            </div>
                        )}
                        {
                            !loading && <>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="bottom"
                                    overlay={
                                        <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                                            <div className="card">
                                                <div className="card-body">
                                                    DCF gives you SOL back if you lose.<br></br>To get all of it, flip the full amount.
                                                </div>
                                            </div>
                                        </Popover>}
                                >
                                    <h5>
                                        YOUR RISK FREE FLIPS
                                        <span className="ms-1 fas fa-circle fa-info-circle fa-2xs cursor-pointer text-secondary"></span>
                                    </h5>
                                </OverlayTrigger>
                                <hr />
                                {
                                    riskFreeFlips.sort((b, a) => a.solAmount - b.solAmount).map((rff, index) => {
                                        return (
                                            <div key={index} className={"d-flex justify-content-between align-items-center" + (index !== riskFreeFlips?.length - 1 ? " mb-3" : "")}><div>
                                                <small>{rff?.campaignName} (expires {DateTime.fromISO(rff?.expireAt).minus({ days: 1 }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATE_SHORT)})</small>
                                                <br />
                                                {
                                                    rff.status === RiskFreePlaysStatus.AVAILABLE &&
                                                    <span className="h5">{rff.solAmount} SOL</span>
                                                }
                                                {
                                                    rff.status === RiskFreePlaysStatus.ACTIVATED &&
                                                    <span className="h5"><u>{rff.solAmount} SOL</u></span>
                                                }
                                            </div>
                                                <div className="form-check form-switch form-check-md mb-0">
                                                    <input
                                                        className="form-check-input cursor-pointer"
                                                        type="checkbox"
                                                        id="checkPrivacy9"
                                                        defaultChecked={rff.status === RiskFreePlaysStatus.ACTIVATED}
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            toggleRiskFreeFlip(rff);
                                                        }}
                                                    />
                                                </div>
                                            </div>)
                                    })
                                }
                            </>
                        }
                    </div>
                    {
                        !loading &&
                        <div className="card-footer text-center">
                            <button
                                className="btn btn-block w-100 btn-lg my-2 rounded-sm btn-warning"
                                onClick={props.onHide}>
                                DONE
                            </button>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal >
    );
}

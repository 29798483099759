import { useContext, useState } from "react";
import { getRewards } from "../../api-smart-contracts/dcf";
import { SideBetsContext } from "../../contexts/side-bets.context";
import { getTokenRewards } from "../../services/solana-token-claim.service";
import { CHALLENGES, constants, opposite } from "../../utils/constants";
import { ResultPage } from "../pages/ResultPage";

const DCF_ID = CHALLENGES[0].id;

const CoinFlipWizardStepThree = ({
  wallet,
  community,
  currentWinStreak,
  streak,
  winStreakImageUrl,
  amount,
  won,
  side,
  id,
  isFinalized,
  isSuperStreak,
  depositTxn,
  skipReward,
  createdAt,
  coinWalletId,
  whaleMode,
  proMode,
  tokenMode,
  tokenAmount,
  style,
  onNext,
  onOpenShareModal,
  priorityFee,
  handleTokenSwapFailure
}: any) => {
  const [rewardLoading, setRewardLoading] = useState(false);
  const { sideBetsState, fetch } = useContext(SideBetsContext);
  const [rewardClaimed, setRewardClaimed] = useState(false);

  const handleSideBet = () => {
    try {
      if (sideBetsState?.isActive) {
        fetch();
      }
    } catch {
    }
  }

  const claimRewards = async () => {
    setRewardLoading(true);
    handleSideBet();
    try {
      if (!rewardClaimed) {
        await getRewards(wallet, id, amount, side, priorityFee);
        setRewardClaimed(true);
      }

      if (tokenMode) {
        await getTokenRewards(wallet, amount);
      }
      setRewardLoading(false);
      onNext();
    } catch (e) {
      console.log(e);
      if (tokenMode) handleTokenSwapFailure();
      setRewardLoading(false);
    }
  }

  return (
    <ResultPage
      wallet={wallet}
      coinHeadsImageUrl={community?.assets?.coinHeadsImageUrl ?? (!proMode ? constants.BUTTONS.BabyCoin : (style.includes('dark') ? 'https://i.imgur.com/gN0mJx0.gif' : 'https://i.imgur.com/mYUlUD9.gif'))}
      coinTailsImageUrl={community?.assets?.coinTailsImageUrl ?? (!proMode ? constants.BUTTONS.BabyCoinButt : (style.includes('dark') ? 'https://i.imgur.com/4AHibHW.gif' : 'https://i.imgur.com/rtm7GVR.gif'))}
      buttonClaimReward={community?.assets?.buttonClaimReward ?? (whaleMode ? constants.BUTTONS_WHALE.ClaimReward : constants.BUTTONS.ClaimReward)}
      buttonDoubleOrNothing={community?.assets?.buttonDoubleOrNothing ?? (whaleMode ? constants.BUTTONS_WHALE.DoubleOrNothing : constants.BUTTONS.DoubleOrNothing)}
      winningImageUrl={winStreakImageUrl}
      buttonConstants={constants.BUTTONS}
      isWhitelabel={community.id !== DCF_ID}
      currentWinStreak={currentWinStreak}
      streak={streak}
      price={amount}
      winner={won ? side : opposite(side)}
      side={side}
      isFinalized={isFinalized}
      claimRewards={claimRewards}
      goBack={() => { handleSideBet(); onNext() }}
      rewardLoading={rewardLoading}
      isSuperStreak={isSuperStreak}
      coinFlipId={id}
      skipReward={skipReward}
      createdAt={createdAt}
      isOwner={wallet?.publicKey?.toString() === coinWalletId}
      depositTxn={depositTxn}
      proMode={proMode}
      tokenMode={tokenMode}
      tokenAmount={tokenAmount}
      rewardClaimed={rewardClaimed}
      onOpenShareModal={onOpenShareModal} />
  );
};

export default CoinFlipWizardStepThree;
import { get } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const getGeolocation = async (walletId: string | null = null) => {
    let url = `${BASE_URL}/geolocation`;
    if (walletId?.length) {
        url = `${url}?walletId=${walletId}`;
    }
    const response = await get(url);
    return response?.payload;
};

import { useState } from "react";

const BetAmountButton = ({
  amount,
  onSelect
}: any) => {

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 300); // 2000 milliseconds = 2 seconds
    onSelect();
  };

  return (
    <button
      className={`
        btn 
        btn-dark
        btn-pro
        mb-1 
        w-100 
        font-buttons 
        rounded-0 
        d-inline-flex 
        justify-content-center
        letter-spacing-1 
        ${clicked ? ' clicked' : ''}
    `}
      onClick={() => handleClick()}>
      {
        amount === 'CLEAR' && <>{amount}</>
      }
      {
        amount !== 'CLEAR' && <>
          {clicked ? `+ ${amount}` : `+ ${amount}`}
        </>
      }
    </button>
  )
};

export default BetAmountButton;
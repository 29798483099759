
import { constants } from "../../utils/constants";


const CoinFlipWizardVirtualStepTwo = ({
  side,
  status
}: any) => {

  return (
    <div className="play step2 py-5 py-md-0">
      <div className="form-signin">
        <>
          <img className="coin-flipping" src={constants.BUTTONS.Processing} alt="processing" />
          <h3 className="fst-italic">{status?.split('_').join(' ')}<small><span className="dot dot1">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span></small></h3>
          <h3 className="mb-5 mb-lg-0">I LIKE <u>{side === "H" ? "HEADS" : "TAILS"}</u></h3>
        </>
      </div>
    </div>
  );
};

export default CoinFlipWizardVirtualStepTwo;

import { useState } from "react";

const ImageToggleButton = ({
  name,
  buttonImageSrc,
  clickedImageSrc,
  isSelected,
  onSelect
}: any) => {

  const [currentImage, setCurrentImage] = useState(buttonImageSrc);

  const handleClick = () => {
    if (clickedImageSrc?.length) {
      setCurrentImage(clickedImageSrc);
      setTimeout(() => {
        setCurrentImage(clickedImageSrc);
      }, 2000); // 2000 milliseconds = 2 seconds
    }
    onSelect();
  };

  return (
    <img onClick={handleClick}
      className={"cursor-pointer double-button toggle-button" + (isSelected ? " selected" : "")}
      src={currentImage}
      alt={name}
      width="100%"
      height="100%" />
  )
};

export default ImageToggleButton;
import { calculatePayout, parseValue, numberWithCommas } from "../side-bets/helpers";
import InsuranceProgressBar from "./InsuranceProgressBar";

const BEGIN_FLIPPING = "https://i.imgur.com/Y7atKxp.png";

const InsuranceActive = ({ isDarkMode, coinOp, onHide }) => {
    const { number } = parseValue(coinOp.choice);
    return <>
        <div className="py-3">
            <img
                className="img-fluid"
                src={isDarkMode ? 'https://i.imgur.com/rM7gRLw.png' : `https://i.imgur.com/sXRhmwW.png`}
                alt="Insurance" />
            <h4 className="mt-2 mb-3 text-center">IF YOU <span style={{ color: coinOp.choice.includes("L") ? '#FF3366' : '#198754' }}>{coinOp.choice.includes("L") ? 'LOSE' : 'WIN'}</span> A {coinOp.choice.includes("2") ? '2' : '3'}-STREAK</h4>
            <div className="d-flex justify-content-center mb-3">
                <InsuranceProgressBar
                    choice={coinOp?.choice}
                    mainGameHistory={coinOp?.mainGameHistory}
                    size='l'
                    direction='horizontal'
                    onTapped={null} />
            </div>
            <h3 className="text-success my-3 text-center">PAYS <u>{numberWithCommas(calculatePayout(number, coinOp.amount))}</u> SOL</h3>
            <img
                onClick={onHide}
                className="cursor-pointer double-or-nothing-button mb-1"
                src={BEGIN_FLIPPING}
                alt="BEGIN FLIPPING"
                width="100%"
                height="100%"
            />
        </div>
    </>
};

export default InsuranceActive;
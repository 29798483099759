


import { get } from "../utils/http.util";

const BASE_URL = 'https://stream2.degenpusher.com:9876';

export const getLegendaryRaffleEntries = async () => {
    const url = `${BASE_URL}/all_entries`
    const response = await get(url);
    return response;
}

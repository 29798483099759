import { useWallet } from '@solana/wallet-adapter-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { getGeolocation } from '../api/geolocation.service';
import { VirtualCoinFlipToast } from '../components/toasts/VirtualCoinFlipToast';
import { loadState } from '../utils/localStorage';
import { AuthorizationContext } from './auth.context';

interface UserContextValue {
  walletId: any,
  restricted: any,
  countryName: any;
  geolocate(walletId: string | null): any;
  connect(walletId: any): any;
}

const UserContext = createContext<UserContextValue>({
  walletId: null,
  restricted: false,
  countryName: false,
  geolocate() { },
  connect() { }
});

const UserProvider = (props: any) => {
  const wallet = useWallet();

  const { auth } = useContext(AuthorizationContext);

  const [walletId, setWalletId] = useState<any>(loadState()?.username ?? null);
  const [restricted, setRestricted] = useState<any>(true);
  const [countryName, setCountryName] = useState<any>(null);
  const [showToast, setShowToast] = useState<boolean>(false);

  useEffect(() => {
    geolocate(walletId ?? wallet?.publicKey?.toString());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (restricted && walletId && auth?.username !== walletId) {
      geolocate(auth?.username);
    }
    // eslint-disable-next-line
  }, [auth]);

  const geolocate = async (id: string | any = null) => {
    try {
      const { 
        restricted, 
        walletId, 
        countryName 
      }: any = await getGeolocation(id);
      // setShowToast(restricted);
      setRestricted(restricted);
      setWalletId(walletId);
      setCountryName(countryName);
      return restricted;
    }
    catch (e) {
      console.log(e);
    }
  }

  const connect = async (walletId: string) => {
    if (restricted) {
      return await geolocate(walletId);
    }
  };

  return (
    <div>
      <UserContext.Provider value={{ walletId, restricted, countryName, geolocate, connect }}>
        {props.children}
        <VirtualCoinFlipToast show={showToast} onClose={() => setShowToast(false)}></VirtualCoinFlipToast>
      </UserContext.Provider>
    </div>
  )
};

export { UserContext, UserProvider };
// import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { ordinal_suffix_of } from "../../utils/helpers";
// const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// const constants = {
//   DEGEN_HOURS_START_1: DateTime.fromISO(`2022-07-07T17:00:00.000`, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL),
//   DEGEN_HOURS_START_2: DateTime.fromISO(`2022-07-08T07:00:00.000`, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL),
//   UNBOX_START_1: DateTime.fromISO(`2022-07-19T18:00:00.000`, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL),
//   UNBOX_START_2: DateTime.fromISO(`2022-07-08T09:00:00.000`, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL),
// }
const NEXT_STREAK: any = {
  3: 5,
  5: 7,
  7: 10
};

const GREETINGS = [
  "Congrats!",
  "Woohoo!",
  "Cheers!",
  "Way to go!",
  "Hooray!",
  "Fantastic!",
  "You did it!",
  "Well done!",
  "Keep it up!",
  "Bravo!",
  "Keep going!",
  "Well played!",
  "Nice flip!",
  "Good job!",
  "Great job!",
  "What a flip!",
  "You're on fire!"
];

const HIGH_STREAK_MESSAGES = [
  "This is getting ridiculous. No more tickets on this streak.",
  "You're on a hot streak! No more tickets on this streak.",
  "Jeez. No more bonus tickets.",
  "That's improbable. No more tickets on this streak.",
  "Never tell me the odds.",
];

const LOSE_STREAK_MESSAGES = [
  "This is getting ridiculous. No more bonus tickets.",
  "Wow, I can't believe it. You actually won something. Good for you!",
  "Wow, that losing streak.",
  "You won the max amount of tickets for losing. I am sorry.",
  "Never tell me the odds."
];

const BABY_COIN = "https://i.imgur.com/FjUZXhp.png";
// const MAIN_RAFFLE = "https://i.imgur.com/NSU0AQo.png";
// const RAFFLE_IMAGES = [
//   MAIN_RAFFLE,
//   "https://i.imgur.com/NYfC04f.png",
//   "https://i.imgur.com/1nKZBeV.png",
//   "https://i.imgur.com/Y8znpiR.png",
//   "https://i.imgur.com/VfqEvbr.png"
// ];

const rngGreeting = () => {
  return GREETINGS[Math.floor(Math.random() * GREETINGS.length)];
};

const rngHotStreakMessage = () => {
  return HIGH_STREAK_MESSAGES[Math.floor(Math.random() * HIGH_STREAK_MESSAGES.length)];
};

const rngLoseStreakMessage = () => {
  return LOSE_STREAK_MESSAGES[Math.floor(Math.random() * LOSE_STREAK_MESSAGES.length)];
};


// const rngFragmentImage = () => {
//   return RAFFLE_IMAGES[Math.floor(Math.random() * RAFFLE_IMAGES.length)];
// };

const getFragmentsClassName = (streak: number) => {
  if (streak === 3) return 'fw-semibold';
  else if (streak === 5) return 'fw-semibold text-success';
  else if (streak === 7) return 'fw-bolder text-success';
  else if (streak === 10) return 'fw-bold text-success text-decoration-underline';
  return 'fw-light';
}

export const ToastNewRaffles = ({ streak, count, show, won, onClose }: any) => {
  const [greeting] = useState(rngGreeting().toLocaleUpperCase())
  const [hotMessage] = useState(rngHotStreakMessage())
  const [coldMessage] = useState(rngLoseStreakMessage());
  const [fragmentImage] = useState(BABY_COIN);

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (counter !== count) {
        setCounter(1 + counter);
      }
    }, count < 690 ? 10 : 1);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [counter]);

  return (
    <ToastContainer position="bottom-start" className="p-3 position-fixed">
      <Toast className="d-inline-block m-1" onClose={onClose} show={show} delay={24000} autohide>
        <Toast.Body>
          <>
            <div className="d-flex flex-row">
              <img className='image-xs rounded-circle me-1'
                src={fragmentImage}
                alt={'dfc'} />
              <div className="d-flex flex-column ms-1 w-100">
                <h6 className="mb-0 d-flex-inline">
                  {greeting}<br />
                  <span className="d-flex-inline">
                    YOU'VE EARNED <span className={getFragmentsClassName(streak) + " d-flex-inline"}>{count > 1 ? counter : 'A'}</span> <span className={getFragmentsClassName(streak) + " d-flex-inline"}>{count > 1 ? 'TICKETS' : 'TICKET'}</span>.
                  </span>
                </h6>
                <div className="d-inline-flex">
                  <small className="">
                    {
                      streak < 10 &&
                      <>
                        Keep it up and earn more on your <span className="fst-italic d-flex-inline">{ordinal_suffix_of(NEXT_STREAK[streak])} streak</span>!
                      </>
                    }
                    {
                      streak === 10 && <>
                        {
                          won &&
                          <>
                            {hotMessage}
                          </>
                        }
                        {
                          !won &&
                          <>
                            {coldMessage}
                          </>
                        }

                      </>
                    }
                  </small>
                </div>
                {/* <span className="mb-0 d-flex">
                  <a href={`https://twitter.com/degenfatcats`} target="_blank" rel="noopener noreferrer">
                    More info at&nbsp;
                    <b className="">
                      @degenfatcats
                    </b><i className="fa-brands fa-sm fa-twitter ms-2"></i></a>
                </span> */}
              </div>
            </div>
          </>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
import { timeAgo } from "../utils/dates";

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url) => () => openInNewTab(url)

function formatNumberShorthand(number) {
    let result = number;
    let suffix = '';

    if (number >= 1e9) {
        result = number / 1e9;
        suffix = 'b';
    } else if (number >= 1e6) {
        result = number / 1e6;
        suffix = 'm';
    } else if (number >= 1e3) {
        result = number / 1e3;
        suffix = 'k';
    }

    // Remove .0 if the number is an exact k, m, or b
    return `${Number.isInteger(result) ? result : result.toFixed(1)}${suffix}`;
}

const getAmountText = ({ amount, tokenId, tokenAmount }) => {
    if (!tokenId) return amount;
    return `${formatNumberShorthand(tokenAmount)}`; // todo: get token name from tokenId
}

const displayToken = ({ tokenId }) => {
    if (!!tokenId?.length) {return (
        <>
            <img src="https://arweave.net/WI3PL7i6hjXsb-fLsoM1spR0lQNNnwmWNrxESKGM8Rw" className="img-fluid mb-1 mx-1 rounded-circle" alt="WIF" style={{ maxWidth: '16px' }} />WIF
        </>
    )}
    return <></>
}

export function FlipRow({ flip, type, defaultProfilePicture }) {
    let user = flip.nickname ?? `Wallet (${flip?.walletId?.slice(0, 4)})`;
    let color = 'inherit';
    let text = '';
    if (type === "winStreak") {
        text = ` is on a ${flip.winStreak} win streak.`
    } else {
        text = ` and got rugged`;
        if (flip.won) {
            text = ` and`;
            color = flip.amount < 1 ? 'MediumSeaGreen' : '#f4a200';
            color = flip.amount < 20 ? color : '#9791ff';
        }
    }

    return (
        <li key={flip.createdAt} className="list-group-item d-flex p-2">
            <div className="d-flex">
                <div className="profile-picture">
                    <img className='image rounded-circle'
                        src={flip.profileImageUrl ?? defaultProfilePicture}
                        alt={flip.nickname?.charAt(0) ?? ''}
                        onError={(e) => {
                            if (e.target.src !== defaultProfilePicture) { e.target.onerror = null; e.target.src = defaultProfilePicture; }
                        }} />

                </div>
                {
                    type === 'winStreak' &&
                    <div className="title mb-auto ms-2">{user}{text}</div>
                }
                {
                    type !== 'winStreak' && <>
                        {
                            !!flip.won && <>
                                {
                                    flip.amount < 1 &&
                                    <div className="title mb-auto ms-2">{user} flipped {getAmountText(flip)}{displayToken(flip)}{text} {flip.won && <span style={{ color }}> doubled{flip.winStreak > 1 ? ` ${flip.winStreak} times.` : '.'}</span>}</div>
                                }
                                {
                                    flip.amount >= 1 &&
                                    <div className={"title mb-auto ms-2" + (flip?.amount >= 3 ? " whale-text" : "")} style={{ color }}>{user} flipped{getAmountText(flip)}{displayToken(flip)}{text} {flip.won && <span> doubled{flip.winStreak > 1 ? ` ${flip.winStreak} times.` : '.'}</span>}</div>
                                }
                            </>
                        }
                        {
                            !flip.won && <>
                                {
                                    flip.amount < 1 &&
                                    <div className="title mb-auto ms-2">{user} flipped {getAmountText(flip)}{displayToken(flip)}{text}</div>
                                }
                                {
                                    flip.amount >= 1 &&
                                    <div className={"title mb-auto ms-2" + (flip?.amount >= 3 ? " whale-text" : "")} style={{ color }}>{user} flipped{getAmountText(flip)}{displayToken(flip)}{text}</div>
                                }
                            </>
                        }
                    </>
                }
                <small className="ms-auto mt-auto time-in-row">{timeAgo(flip.createdAt)}</small>
            </div>
        </li>
    )
}
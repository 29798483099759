import { get } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_ATLANTIS_API_URL ?? 'https://dev-api.degentreasures.com/v1';

export const getPromoFlip = async (promoId: any, flipId: any, Authorization = '') => {
  const url = `${BASE_URL}/promos/${promoId}/flips/${flipId}`
  const response = await get(url, { Authorization });
  return response?.payload;
}

export const getMyFragments = async (promoId: any, Authorization = '') => {
  const url = `${BASE_URL}/promos/${promoId}/fragments`
  const response = await get(url, { Authorization });
  return response?.payload;
}

export const getAnnouncements = async (walletId: any = '') => {
  const url = `${BASE_URL}/announcements?walletId=${walletId}`
  const response = await get(url);
  return response?.payload;
}

export const getActivePromos = async(Authorization = '') => {
  const url = `${BASE_URL}/promos/active`
  const response = await get(url, { Authorization });
  return response?.payload;
}
import { createContext, useState } from "react";

const LIVEFEED_SETTING = 'liveFeed';

interface SettingContextValue {
    liveFeed: boolean | any;
    setLiveFeedMode(liveFeed: boolean): void;
}

const SettingContext = createContext<SettingContextValue>({
    liveFeed: true,
    setLiveFeedMode: () => {},
});

const SettingProvider = (props: any) => {
    const [liveFeed, setLiveFeed] = useState(localStorage.getItem(LIVEFEED_SETTING) !== '0');

    const setLiveFeedMode = (liveFeed: boolean) =>{
        setLiveFeed(liveFeed);
        localStorage.setItem(LIVEFEED_SETTING, liveFeed ? '1' : '0');
    };

    return (
        <SettingContext.Provider value={{ liveFeed, setLiveFeedMode }}>
            {props.children}
        </SettingContext.Provider>
    )
};

export { SettingContext, SettingProvider };

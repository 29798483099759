export const customRound = amount => {
    // Check the fractional part of the product
    const fractionalPart = amount % 1;

    // Decide whether to add an epsilon to handle the 0.999 case
    const epsilon = fractionalPart >= 0.999 ? 0.0005 : 0;

    // Compute and return the rounded result
    const roundedProduct = Math.round((amount + epsilon) * 1000) / 1000;

    return roundedProduct;
};

export const parseValue = value => {
    const match = value.match(/^([A-Z])(\d+)$/);

    if (!match) {
        throw new Error('Invalid value format');
    }

    const character = match[1];
    const number = parseInt(match[2], 10);

    return { character, number };
};

export const getChoiceWord = (choice) => {
    const Words = {
        H: "heads",
        T: "tails",
        W: "win",
        L: "loss"
    };
    const { character } = parseValue(choice);
    return Words[character];
}

export const getPluralChoiceWord = (choice) => {
    const Words = {
        H: "heads",
        T: "tails",
        W: "wins",
        L: "losses"
    };
    const { character } = parseValue(choice);
    return Words[character];
}

export const getChoiceString = (choice) => {
    if (!choice) return '';
    const { number } = parseValue(choice);
    return `${number}-${getChoiceWord(choice)}`;
}


export const calculatePayout = (streak, betAmount) => {
    const p = 0.5;
    return customRound(betAmount * (1 / Math.pow(p, streak)));
};

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
import { Modal } from "react-bootstrap";

export function AMLModal({ styleCss, ...props }) {
    const { onHide } = props;
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className="card-body p-4">
                        <h4 className="card-title text-sm text-left">Anti-Money-Laundering Policy</h4>
                        <br/>
                        <h5>For Deposits and Withdrawals</h5>
                        <p>AML Anti-Money-Laundering policy of degencoinflip.com </p>
                        <p>Introduction: degencoinflip.com is operated by OG Labs N.V. having its office at Zuikertuintjeweg z/n (Zuikertuin Tower), Willemstad, Curaçao. Company Registration number 163351</p>

                        <h6>Objective of the AML Policy:</h6>

                        <p>We seek to offer the highest security to all our users and customers on degencoinflip.com for that a three-step account verification is done in order to insure the identity of our customers...</p>

                        <h6>Definition of money laundering:</h6>

                        <p>Money Laundering is understood as:</p>
                        <ul>
                            <li>The conversion or transfer of property, especially money...</li>
                            <li>The concealment or disguise of the true nature, source, location...</li>
                            <li>The acquisition, possession or use of property, knowing, at the time of receipt...</li>
                            <li>Participation in, association to commit, attempts to commit and aiding...</li>
                        </ul>

                        <h6>Organization of the AML for degencoinflip.com:</h6>
                        <p>In accordance with the AML legislation, degencoinflip.com has appointed the “highest level” for the prevention of ML: The full management of OG Labs N.V. are in charge...</p>

                        <h6>AML policy changes and implementation requirements:</h6>
                        <p>Each major change of degencoinflip.com AML policy is subject to be approval by the general management of OG Labs N.V. and the Anti money laundering compliance officer.</p>

                        <h6>Three step Verification:</h6>
                        <h6>Step one verification:</h6>
                        <p>Step one verification must be done by every user and customer to withdraw...</p>

                        <h6>Step two verification:</h6>
                        <p>Step two verification must be done by every user which deposit over 2000 EUR (two thousand Euro) or withdraws any amount...</p>

                        <h6>Step three verification:</h6>
                        <p>Step three verification must be done by every user which deposit over 5000$ (five thousand Dollar) or withdraws over 5000$ (five thousand Dollar) or sends another user over 3000$ (three thousand Dollar) Until step three verification is done the withdraw, tip or deposit will be hold. For step 3 a user/customer will be asked for a source of wealth.</p>

                        <h5>Customer identification and verification (KYC)</h5>
                        <p>The formal identification of customers on entry into commercial relations is a vital element, both for the regulations relating to money laundering and for the KYC policy.</p>
                        <p>This identification relies on the following fundamental principles:</p>
                        <p>A copy of your passport, ID card or driving license, each shown alongside a handwritten note mentioning six random generated numbers...</p>

                        <h6>Proof of Address:</h6>
                        <p>Proof of address will be done via two different electronic checks, which use two different databases...</p>

                        <h6>Source of funds</h6>
                        <p>If a player deposits over a five thousand euro there is a process of understandings the source of wealth (SOW)</p>
                        <ul>
                            <li>Ownership of business</li>
                            <li>Employment</li>
                            <li>Inheritance</li>
                            <li>Investment</li>
                            <li>Family</li>
                        </ul>

                        <h6>Basic document for step one:</h6>
                        <p>The basic document will be accessible via the setting page on degencoinflip.com. Every user must fill out the following information...</p>

                        <h6>Risk management:</h6>
                        <p>To deal with the different risks and different states of wealth in different regions on the earth degencoinflip.com will categorize every nation in three different regions of risk...</p>

                        <h6>Additional measurements.</h6>
                        <p>In addition, an AI which is overseen by the AML compliance officer will look for any unusual behaviour and report it right away to an employee of degencoinflip.com...</p>

                        <h6>Ongoing transaction monitoring</h6>
                        <p>AML-Compliance ensures that an “ongoing transaction monitoring” is conducted to detect transactions which are unusual or suspicious compared to the customer profile...</p>

                        <h6>Reporting of Suspicious transactions on degencoinflip.com</h6>
                        <p>In its internal procedures, degencoinflip.com describes in precise terms, for the attention of its staff members, when it is necessary to report and how to proceed with such reporting...</p>

                        <h6>Record keeping</h6>
                        <p>Records of data obtained for the purpose of identification must be kept for at least ten years after the business relationship has ended...</p>

                        <h6>Training:</h6>
                        <p>degencoinflip.com human employees will make manual controls on a risk based approve for which they get special training...</p>

                        <h6>Auditing</h6>
                        <p>Internal audit regularly establishes missions and reports about AML activities.</p>

                        <h6>Data Security:</h6>
                        <p>All data given by any user/customer will be kept secure, will not be sold or given to anyone else. Only if forced by law, or to prevent money laundering data may be shared with the AML-authority of the affected state...</p>

                        <p>Contact us: If you have any questions about our AML and KYC Policy, please contact us:</p>
                        <p>By email: degencoinflipinbox@proton.me</p>
                        <p>If you have any complaints about our AML and KYC Policy or about the checks done on your Account and your Person, please contact us: By email: degencoinflipinbox@proton.me</p>

                    </div>
                    <div className="card-footer">
                        <button className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning" onClick={onHide}>GOT IT</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

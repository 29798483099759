import { Modal } from "react-bootstrap";
import { useContext } from "react";
import { SettingContext } from "../../contexts/setting.context";

export function SettingModal({
    styleCss,
    profile,
    ...props
}) {

    const { liveFeed, setLiveFeedMode } = useContext(SettingContext);

    const handleLiveFeedToggle = () => {
        setLiveFeedMode(!liveFeed);
        props.onHide();
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName={styleCss + `-color`}
        >
            <Modal.Body className={"p-0"}>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title text-sm text-left">Settings</h5>

                        <div className="d-flex justify-content-center my-3">
                            <div className="form-check form-switch">

                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                >
                                    Show Live Feed
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                        checked={liveFeed}
                                        onClick={(handleLiveFeedToggle)}
                                        onChange={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    />
                                </label>

                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>

        </Modal>
    );
}
import { DateTime } from "luxon";
import { SideBetsGame } from "../utils/constants";
import { get, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_COINOP_URL ?? 'https://dev-api.degencoinop.com/v1';

export const initCoinOp = async (
    gameId = SideBetsGame.Slug,
    { amount, choice }: any,
    Authorization = ''
) => {
    const response = await post(
        `${BASE_URL}/games/${gameId}/coinOps`,
        { amount, choice, serialized: true },
        { Authorization }
    );
    return response?.data?.payload;
};

export const activateCoinOp = async (
    gameId = SideBetsGame.Slug,
    coinOpId = '',
    Authorization = ''
) => {
    const response = await post(
        `${BASE_URL}/games/${gameId}/coinOps/${coinOpId}/activate`,
        { serialized: true },
        { Authorization }
    );
    return response?.data?.payload;
};

export const getCoinOp = async (
    gameId = SideBetsGame.Slug,
    coinOpId = '',
    Authorization = ''
) => {
    const response = await get(
        `${BASE_URL}/games/${gameId}/coinOps/${coinOpId}?serialized=true`,
        { Authorization }
    );
    return response?.payload;
};

export const getCoinOps = async (startTime = DateTime.utc().toISO(), gameId = SideBetsGame.Slug) => {
    const response = await get(`${BASE_URL}/games/${gameId}/coinOps/events?startTime=${startTime}`);
    return response?.payload;
};

export const getCoinOpsByWallet = async (startTime = DateTime.utc().toISO(), walletId = '', gameId = SideBetsGame.Slug) => {
    const response = await get(`${BASE_URL}/games/${gameId}/wallets/${walletId}/coinOps/events?startTime=${startTime}`);
    return response?.payload;
};

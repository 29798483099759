import { useWallet } from "@solana/wallet-adapter-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { confetti } from '../../utils/confetti';

import { initCoinFlip, processCoinFlipWithMemo } from "../../api/coin-flip.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { CoinFlipStatus, CoinSides, constants, VALID_AMOUNTS } from "../../utils/constants";
import { depositSol } from "../../services/solana-deposit.service";
import { getUIFX } from "../../components/coin-flip-wizard/streak.ui";
import CoinFlipWizardStepThree from "../../components/coin-flip-wizard/CoinFlipWizardStepThree";
import { CommunityContext } from "../../contexts/community.context";
import { ToastRugged } from "../../components/toasts/RuggedToast";
import { ToastCongrats } from "../../components/toasts/CongratsToast";
import { AudioContext } from "../../contexts/audio.context";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const confettiAnimation = confetti;

export const Deeplink = () => {
  const wallet = useWallet();
  const navigate = useNavigate();
  const { auth, signIn, isExpired } = useContext(AuthorizationContext);
  const { community } = useContext(CommunityContext);
  const { play } = useContext(AudioContext);

  const [inputParams, setInputParams] = useState();
  const [coinFlip, setCoinFlip] = useState();
  const [loading, setLoading] = useState(true);


  const execCompleteFlip = async (amount, side) => {
    if (!wallet) {
      await wallet.connect();
    }
    try {
      let authToken = auth;
      if (!authToken || isExpired()) {
        authToken = await signIn(wallet?.publicKey?.toString());
      }

      const coinFlip = {
        side,
        amount,
        isMobile
      };

      const initializedCoinFlip = await initCoinFlip(coinFlip, authToken?.idToken);
      const { id } = initializedCoinFlip;

      const depositTxn = await depositSol(wallet, amount, id, side);

      const response = await processCoinFlipWithMemo(id, depositTxn, authToken?.idToken);
      setCoinFlip(response);
    } catch {
      goHome();
    }
  }

  const getInputParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    let amount = +params.get('amount');
    const side = params.get('side') ?? CoinSides.HEADS;
    if (!VALID_AMOUNTS.includes(amount)) {
      amount = VALID_AMOUNTS[0];
    }
    return [amount, side];
  }

  useEffect(() => {
    const [amount, side] = getInputParams();
    setInputParams({
      amount, side
    })
    wallet.connect();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const exec = (amount, side) => {
      execCompleteFlip(amount, side);
    }

    if (wallet.connected) {
      const [amount, side] = getInputParams();
      exec(amount, side);
    }
    // eslint-disable-next-line
  }, [wallet]);


  useEffect(() => {
    if (coinFlip) {
      setLoading(false);
      handleUI(coinFlip);
    }

    // eslint-disable-next-line
  }, [coinFlip]);

  const playConfettiAnimation = (defaultTimeout = 1000, min, max) => {
    confettiAnimation?.start(defaultTimeout, min, max);
    setTimeout(function () {
      confettiAnimation?.stop()
    }, defaultTimeout);
  };

  const [superStreak, setSuperStreak] = useState(false);
  const [congratsToastShow, setCongratsToastShow] = useState(false);
  const [ruggedToastShow, setRuggedToastShow] = useState(false);

  const clearAllToasts = () => {
    setCongratsToastShow(false);
    setRuggedToastShow(false);
  }

  const handleUI = async (coinFlip) => {

    const {
      won,
      winStreak,
      winStreakWhale,
      loseStreakWhale,
      loseStreak
    } = coinFlip;
    const prevWinStreak = 0;
    const streak = won ? winStreak : loseStreak;
    // const isSuperStreak = streak >= 7;
    setSuperStreak(won ? streak >= 6 : streak > 6);

    const { audio, confettiLevel } = getUIFX(
      won,
      winStreak,
      loseStreak,
      prevWinStreak,
      winStreakWhale > 0 || loseStreakWhale > 0
    );
    play(audio);

    if (confettiLevel) {
      playConfettiAnimation(...confettiLevel);
    }

    if (won) {
      clearAllToasts();
      setCongratsToastShow(true);
    }
    else {
      clearAllToasts();
      setRuggedToastShow(true);
    }
  }

  const goHome = () => {
    navigate("../../", { replace: true });
  }

  return (
    <div className="text-center d-flex main-header h-100vh-desktop">
      <ToastRugged
        show={ruggedToastShow}
        onClose={() => setRuggedToastShow(false)}>
      </ToastRugged>
      <ToastCongrats
        show={congratsToastShow}
        onClose={() => setCongratsToastShow(false)}>
      </ToastCongrats>
      {
        loading &&
        <div className="play step2 py-5 py-md-0">
          <div className="form-signin">
            <img className={"coin-flipping"} src={constants.BUTTONS.Processing} alt="processing" />
            {wallet.connected && (<>
              <h3 className="fst-italic">{"WAITING FOR DEPOSIT"?.split('_').join(' ')}<small><span className="dot dot1">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span></small></h3>
              <h3 className="mb-5 mb-lg-0"><u>{inputParams?.side === CoinSides.HEADS ? "HEADS" : "TAILS"}</u> FOR <u>{inputParams?.amount}</u> SOL</h3>
            </>
            )}
            {!wallet.connected && (
              <WalletMultiButton
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
            )}
          </div>
        </div>
      }

      {
        !loading && !!coinFlip && <>
          <CoinFlipWizardStepThree
            wallet={wallet}
            community={community}
            loading={loading}
            currentWinStreak={coinFlip.won ? coinFlip?.winStreak : coinFlip?.loseStreak}
            streak={coinFlip.won ? coinFlip?.winStreak : coinFlip?.loseStreak}
            amount={coinFlip?.amount}
            won={coinFlip?.won}
            side={coinFlip?.side}
            isFinalized={coinFlip?.status === CoinFlipStatus.FINALIZED}
            isSuperStreak={superStreak}
            id={coinFlip?.id}
            depositTxn={coinFlip?.depositTxn}
            createdAt={coinFlip?.createdAt}
            coinWalletId={coinFlip?.walletId}
            onNext={() => goHome()} />
        </>
      }

    </div>);
};
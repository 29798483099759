import { useEffect, useState } from "react";
import useSideBets from "../side-bets/useSidebets";
import { InsuranceModal } from "./InsuranceModal";
import InsuranceProgressBar from "./InsuranceProgressBar";

const InsuranceWidget = ({ style }) => {
    const {
        lastestSideBet,
        loading,
        amount,
        selectedChoice,
        isActive,
        isPendingReward,
        setAmount,
        setChoice,
        onSubmit,
        claimReward,
        fetchLatestSideBet,
    } = useSideBets();

    useEffect(() => {
        fetchLatestSideBet();
        // eslint-disable-next-line
    }, []);

    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => {
        fetchLatestSideBet();
        setShowModal(true);
    }
    const handleModalClose = () => setShowModal(false);

    return (
        <div className="d-flex justify-content-center">
            {
                (!lastestSideBet || (!isActive && !isPendingReward)) && <>
                {
                    !loading &&
                    <a
                        href="#!"
                        onClick={handleModalOpen}
                        className={`no-decoration btn btn-sm btn-outline-dark`}
                    >
                        <span className="fa-stack fa-2xs me-1">
                            <i className="fa-solid fa-circle fa-stack-2x"></i>
                            <i className={`fas fa-shield-alt fa-stack-1x fa-inverse fa-inverse-${style}`}></i>
                        </span>
                        <u>ADD INSURANCE</u>
                    </a>
                }
                {
                    loading &&
                    <i className="fas fa-circle-notch fa-spin"></i>
                }
                </>
            }
            {
                lastestSideBet && (isActive || isPendingReward) &&
                <>
                    <InsuranceProgressBar
                        choice={lastestSideBet.choice}
                        mainGameHistory={lastestSideBet.mainGameHistory}
                        size='l'
                        direction='horizontal'
                        isPendingReward={isPendingReward}
                        onTapped={handleModalOpen} />
                </>
            }
            {
                showModal &&
                <InsuranceModal
                    coinOp={lastestSideBet}
                    show={showModal}
                    loading={loading}
                    amount={amount}
                    selectedChoice={selectedChoice}
                    isActive={isActive}
                    isPendingReward={isPendingReward}
                    setAmount={setAmount}
                    setChoice={setChoice}
                    onSubmit={onSubmit}
                    claimReward={claimReward}
                    onHide={handleModalClose}
                />
            }
        </div>
    )
};


export default InsuranceWidget;
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getWeeklyVolume } from "../../api/analytics.service";
import { CHALLENGES } from "../../utils/constants";
import { FlipRowPlaceholder } from "../FlipRowPlaceholder";

const now = DateTime.utc();
const thisStartOfWeek = now.startOf('week');
const earliestDate = DateTime.fromISO('2023-03-06T00:00:00.000Z');

// const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const EVENT_ENDTIME = DateTime.fromISO(`2023-03-20T19:00:00.000`, { zone: 'UTC' });

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
};

const formatCash = n => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

const PlayerRow = ({ nickname, profileImageUrl, rank, solPoints }) => <div className="align-items-center p-2 border-200 row border">

  <div className="py-1 col">
    <div className="d-flex align-items-center">
      <div className="d-flex position-relative">
        <h6 className="d-flex mb-0" align="center">
          {rank === 1 ? `👑` : `#${rank}`}
        </h6>
      </div>
      <div className="avatar avatar-xl ms-2 me-2">
        <img className="rounded-circle img-fluid" src={profileImageUrl ?? CHALLENGES[0].profileImageUrl} alt={nickname} onError={(e) => {
          if (e.target.src !== CHALLENGES[0].profileImageUrl) { e.target.onerror = null; e.target.src = CHALLENGES[0].profileImageUrl; }
        }} />
      </div>
      <div className="d-flex position-relative">
        <h6 className="d-flex mb-0" align="center">
          {nickname}
        </h6>
      </div>
    </div>
  </div>
  {
    solPoints > 0 &&
    <div className="col">
      <div className="justify-content-end align-items-center row">
        <div className="col-auto pe-0">
          <div className="fs--1 fw-semi-bold">{formatCash(solPoints)} pts</div>
        </div>
      </div>
    </div>
  }
</div>

export function VolumeRankingModal({ walletId, styleCss, authToken, ...props }) {
  const [pageNum] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startOfWeek, setStartOfWeek] = useState(thisStartOfWeek);
  const [maxSolAmount, setMaxSolAmount] = useState(0);

  useEffect(() => {
    fetchLeaderboard(startOfWeek.toFormat('yyyy-MM-dd'));
    // eslint-disable-next-line
  }, [startOfWeek]);

  const fetchLeaderboard = async (date) => {
    setLoading(true);
    const list = await getWeeklyVolume(date, authToken);
    setMaxSolAmount(list[0]?.solAmount / 3);
    setData(list);
    setLoading(false);
  };

  const goPrevWeek = () => {
    const prevStartOfWeek = startOfWeek.minus({ weeks: 1 });
    setStartOfWeek(prevStartOfWeek);
  }

  const goNextWeek = () => {
    const nextStartOfWeek = startOfWeek.plus({ weeks: 1 });
    setStartOfWeek(nextStartOfWeek);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `-color`}
      centered
    >
      <Modal.Body className={"p-0 "}>
        <button id="x" onClick={() => { props.onHide() }}>
          <i className={"fa-regular fa-lg fa-circle-xmark"}></i>
        </button>
        <div className="card card-user shadow-lg">
          <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '484px', overflow: 'scroll' }}>
            <h5 className="text-center">
              <span className="fas fa-ranking-star me-3 text-warning"></span>
              DCF LEAGUE
              <span className="fas fa-ranking-star ms-3 text-warning"></span>
            </h5>
            <h6 className="text-center">
              {
                earliestDate < startOfWeek &&
                <span className="fas fa-chevron-left fa-s me-3 cursor-pointer" onClick={goPrevWeek}></span>
              }
              {startOfWeek.toLocaleString(DateTime.DATE_FULL)} - {startOfWeek.plus({ weeks: 1 }).toLocaleString(DateTime.DATE_FULL)}
              {
                thisStartOfWeek.toSeconds() !== startOfWeek.toSeconds() &&
                <span className="fas fa-chevron-right fa-s ms-3 cursor-pointer" onClick={goNextWeek}></span>
              }
            </h6>
            {
              pageNum === 0 &&
              <div className="px-2">
                {
                  loading && new Array(6).fill(null).map((_, i) => {
                    return <FlipRowPlaceholder key={i} n={i} />
                  })
                }
                {
                  !loading && data?.map((item, index) => <PlayerRow key={index} {...item} maxSolAmount={maxSolAmount} />)
                }
              </div>
            }
            {
              pageNum === 1 &&
              <>
                Page 2
              </>
            }
          </div>
          {/* <div className="card-footer">
            <button
              className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
              onClick={() => { props.onClose() }}>
              CLOSE
            </button>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { StyleThemeContext } from "../../../contexts/style-theme.context";
import { CommunityContext } from "../../../contexts/community.context";
import { RecentCoinFlipsProvider } from "../../../contexts/recent-coin-flips.context";
import { TopCoinFlipsProvider } from "../../../contexts/top-coin-flips.context";
import { SideBetsProvider } from "../../../contexts/side-bets.context";
import { SocialBar, ToolBar } from "../../components";
import MenuBar from "../../components/menu-bar";
import InfoBar from "../../components/info-bar";
import LiveDataBar from "../../components/live-data-bar";
import { SettingProvider } from "../../../contexts/setting.context";

const getBgImageUrl = (bgImageUrl: string, style: string, community: any) => {
    switch (style) {
        case 'light':
            return bgImageUrl;
        case 'dark':
            return community?.backgroundImageDark ?? bgImageUrl
        case 'dark-black':
            return community?.backgroundImageWhale ?? bgImageUrl
    }
}
const getMobileBgImageUrl = (bgImageUrl: string, style: string, community: any) => {
    switch (style) {
        case 'light':
            return bgImageUrl;
        case 'dark':
            return community?.backgroundImageMobileDark ?? bgImageUrl
        case 'dark-black':
            return (community?.backgroundImageMobileWhale ?? community?.backgroundImageMobileDark) ?? bgImageUrl
    }
}

const ClassicLayout = () => {
    const { style } = useContext(StyleThemeContext);
    const { community } = useContext(CommunityContext);

    const bgImageUrl = isMobile ? community?.backgroundImageMobile ?? community?.backgroundImage : community?.backgroundImage;
    const bgUrl = isMobile ? getMobileBgImageUrl(bgImageUrl, style, community) : getBgImageUrl(bgImageUrl, style, community);
    return (
        <div className={style}>
            <div className={"classic-container h-100vh h-sm-100" + (bgUrl?.length ? ' bg-image' : '') + (community?.slug === "xmas" ? " snow-bg" : "")}
                style={{
                    backgroundImage: `url(${bgUrl}`
                }}>
                <RecentCoinFlipsProvider>
                    <TopCoinFlipsProvider>
                        <SettingProvider>
                        <SideBetsProvider>
                            <LiveDataBar />
                            <MenuBar></MenuBar>
                            <Outlet></Outlet>
                            <ToolBar></ToolBar>
                            <InfoBar social={community}></InfoBar>
                            <SocialBar />
                        </SideBetsProvider>
                        </SettingProvider>
                    </TopCoinFlipsProvider>
                </RecentCoinFlipsProvider>
            </div>
        </div>
    );
};
export default ClassicLayout;
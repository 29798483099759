import { useContext } from "react";
import { AudioContext } from "../../contexts/audio.context";
import ThemeToggler from "../../components/ThemeToggler";
import AudioButton from "../../components/AudioButton";
import { useWallet } from "@solana/wallet-adapter-react";
import { GameModeContext } from "../../contexts/game-mode.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
// import { AnnouncementsContext } from "../../contexts/announcements.context";
// import { AnnouncementsModal } from "../../components/modals/AnnouncementsModal";
import { SettingContext } from "../../contexts/setting.context";
import { Dropdown } from "react-bootstrap";
// import { ChristmasProgressModal } from "../../components/modals/ChristmasProgressModal";

const ButtonDisplay = ({ whaleMode, proMode, tokenMode, style }: any): any => {
  if (!whaleMode && !proMode && !tokenMode) {
    return <>
      <img src="https://i.imgur.com/FjUZXhp.png"
        className="img-fluid me-2"
        alt="mode"
        style={{ maxHeight: '23px' }}
      />NORMAL
    </>
  }
  if (proMode) {
    return <>
      <img src={`${style.includes('dark') ? 'https://i.imgur.com/iXoUjoj.png' : 'https://i.imgur.com/ob14Vxk.png'}`}
        className="img-fluid me-2"
        alt="mode"
        style={{ maxHeight: '23px' }}
      />PRO
    </>
  }
  if (whaleMode) {
    return <>
      <img src="https://i.imgur.com/FJ88cXP.png"
        className="img-fluid me-2"
        alt="mode"
        style={{ maxHeight: '23px' }}
      />WHALE
    </>
  }
  if (tokenMode) {
    return <>
      <img src="https://arweave.net/WI3PL7i6hjXsb-fLsoM1spR0lQNNnwmWNrxESKGM8Rw"
        className="img-fluid me-1 rounded-circle mb-1"
        alt="mode"
        style={{ maxHeight: '20px' }}
      />WIF
    </>
  }
}

const ToolBar = () => {
  const wallet = useWallet();
  const { muted, toggleMute } = useContext(AudioContext);
  const { whaleMode, proMode, tokenMode, toggleProMode, toggleWhaleMode, toggleTokenMode } = useContext(GameModeContext);
  const { toggleDarkBlack } = useContext(StyleThemeContext);
  const { style, canToggle } = useContext(StyleThemeContext);
  // const { announcements, updateDot, showDot } = useContext(AnnouncementsContext);
  const { liveFeed } = useContext(SettingContext);

  // const [showAnnouncementsModal, setShowAnnouncementsModal] = useState(false);
  // const handleNotificationModalOpen = () => setShowAnnouncementsModal(true);
  // const handleNotificationModalClose = () => setShowAnnouncementsModal(false);

  // const [showChristmasPromoModal, setShowChristmasPromoModal] = useState(false);
  // const handleShowChristmasPromoModalOpen = () => setShowChristmasPromoModal(true);
  // const handleShowChristmasPromoModalClose = () => setShowChristmasPromoModal(false);

  const enableWhaleMode = () => {
    toggleWhaleMode();
    toggleDarkBlack();
  };

  // const WhaleButton = () => {
  //   return (
  //     <a href="#!" onClick={ev => ev.preventDefault()} className="ms-sm-2 ms-1">
  //       <button className="btn btn-outline-dark" onClick={enableWhaleMode}>
  //         <img src={whaleMode ? "https://i.imgur.com/FjUZXhp.png" : "https://i.imgur.com/FJ88cXP.png"} className="img-fluid" alt="whale" style={{ maxHeight: '23px' }} />
  //       </button>
  //       <Konami action={enableWhaleMode}></Konami>
  //     </a>
  //   );
  // };

  const ProButton = () => {
    return (
      // <a href="#!" onClick={ev => ev.preventDefault()} className="ms-sm-2 ms-1">
      //   <button className="btn btn-outline-dark d-inline-flex" onClick={toggleProMode}>
      //     <i className={"fas my-auto fa-gear me-2"}></i>
      //     <span>PRO</span>
      //   </button>
      // </a>

      <Dropdown className="ms-sm-2 ms-1">
        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
          <ButtonDisplay whaleMode={whaleMode} proMode={proMode} tokenMode={tokenMode} style={style} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            href="#!"
            onClick={toggleProMode}
            rel="noopener noreferrer">
            PRO <i className={`fas fa-check fa-xs text-success ${proMode ? 'd-inline-flex' : 'd-none'}`}></i>
          </Dropdown.Item>
          <Dropdown.Item
            href="#!"
            onClick={enableWhaleMode}
            rel="noopener noreferrer">
            WHALE <i className={`fas fa-check fa-xs text-success ${whaleMode ? 'd-inline-flex' : 'd-none'}`}></i>
          </Dropdown.Item>
          <Dropdown.Item
            href="#!"
            onClick={toggleTokenMode}
            rel="noopener noreferrer">
            WIF TOKEN <i className={`fas fa-check fa-xs text-success ${tokenMode ? 'd-inline-flex' : 'd-none'}`}></i>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    );
  };

  /* CHRISTMAS 2023 PROMO */
  // const AnnouncementButton = () => {
  //   return (
  //       <button className="btn btn-outline-dark btn-christmas ms-sm-2 ms-1" style={{display:'inline-block', padding: 0, margin: 0, width: '50px', backgroundPosition:'center'}}
  //         onClick={handleShowChristmasPromoModalOpen}
  //       >
  //           <img src="https://imgur.com/dQa70wb.png" alt="christmas" style={{height:'36px'}}/>
  //       </button>

  //   );
  // };
  /**/

  // const NotificationButton = ({ onClickNotification }: any) => {
  //   return (
  //     <button className="btn btn-outline-dark d-block d-sm-flex ms-sm-2 ms-1 position-relative" onClick={onClickNotification}>
  //       <i className={"fas my-auto fa-bell"}></i>
  //       {
  //         showDot &&
  //         <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
  //           <span className="visually-hidden">New alerts</span>
  //         </span>
  //       }
  //     </button>
  //   );
  // };

  return (
    <div className={liveFeed ? "social-icons-left pb-5 pb-lg-0" : "social-icons-left-hide-feed pb-5 pb-lg-0"}>
      <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
        <div className="mt-3 d-flex flex-column mx-auto">
          <div className="d-flex flex-row z-index-10 toolbar">
            <AudioButton muted={muted} toggleMute={toggleMute}></AudioButton>
            {
              canToggle && <ThemeToggler></ThemeToggler>
            }
            {wallet.connected && <ProButton />}
            {/* {
              wallet.connected && <AnnouncementButton />
            }
            {
              wallet.connected && announcements?.length > 0 &&
              <NotificationButton onClickNotification={handleNotificationModalOpen} />
            } */}
            {/* {
              showChristmasPromoModal && <ChristmasProgressModal
                wallet={wallet}
                show={showChristmasPromoModal}
                onHide={handleShowChristmasPromoModalClose}/>
            } */}
            {/* {
              showAnnouncementsModal &&
              <AnnouncementsModal
                walletId={''}
                announcements={announcements}
                show={showAnnouncementsModal}
                onHide={() => { handleNotificationModalClose(); updateDot(); }}
                styleCss={''}
              />
            } */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToolBar;

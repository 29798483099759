  import bs58 from "bs58";
  
  const HeliusURL = process.env.REACT_APP_RPC_URL;
  
  export async function getPriorityFeeEstimate(priorityLevel, transaction) {
    const response = await fetch(HeliusURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "1",
        method: "getPriorityFeeEstimate",
        params: [
          {
            transaction: bs58.encode(transaction.serialize()), // Pass the serialized transaction in Base58
            options: { priority_level: priorityLevel },
          },
        ],
      }),
    });
    const data = await response.json();
    return data.result;
  }
  // async function sendTransactionWithPriorityFee(priorityLevel) {
  //   const transaction = new Transaction();
  //   const transferIx = SystemProgram.transfer({
  //     fromPubkey: fromKeypair.publicKey,
  //     toPubkey,
  //     lamports: 100,
  //   });
  //   transaction.add(transferIx);
  
  //   transaction.recentBlockhash = (
  //     await connection.getLatestBlockhash()
  //   ).blockhash;
  //   transaction.sign(fromKeypair);
  
  //   let feeEstimate = { priorityFeeEstimate: 0 };
  //   if (priorityLevel !== "NONE") {
  //     feeEstimate = await getPriorityFeeEstimate(priorityLevel, transaction);
  //     const computePriceIx = ComputeBudgetProgram.setComputeUnitPrice({
  //       microLamports: feeEstimate.priorityFeeEstimate,
  //     });
  //     transaction.add(computePriceIx);
  //   }
  
  //   try {
  //     const txid = await sendAndConfirmTransaction(connection, transaction, [
  //       fromKeypair,
  //     ]);
  //     console.log(`Transaction sent successfully with signature ${txid}`);
  //   } catch (e) {
  //     console.error(`Failed to send transaction: ${e}`);
  //   }
  // }
  
  // sendTransactionWithPriorityFee("HIGH"); // Choose between "MIN", "LOW", "MEDIUM", "HIGH", "VERYHIGH", "UNSAFEMAX"
  
import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover, ProgressBar } from "react-bootstrap";

import { getBalanceOfWalletId } from '../../api-smart-contracts/dcf';
import { RecentCoinFlipsContext } from "../../contexts/recent-coin-flips.context";
import { HouseWallet } from "../../utils/constants";
import { useWallet } from "@solana/wallet-adapter-react";
import { SettingContext } from "../../contexts/setting.context";
import { customRound } from "../../components/side-bets/helpers";

import HeadDataImage from "../../assets/images/live-data-bar/HeadsCoin.png";
import TailDataImage from "../../assets/images/live-data-bar/TailsCoin.png";

const IS_DEV = process.env.NODE_ENV === 'development';
const INTERVAL_RATE = IS_DEV ? 100000 : 3000;
const DEFAULT_HOUSE_SOL = 3000;

function getUniqueObjects(existingArray: any, newArray: any) {
    const existingIds = new Set(existingArray.map((obj: any) => obj.id));
    return newArray.filter((obj: any) => !existingIds.has(obj.id));
}

const LiveDataBar = () => {
    const wallet: any = useWallet();
    const { recentCoinFlips, fetch } = useContext(RecentCoinFlipsContext);
    const { liveFeed } = useContext(SettingContext);

    const [houseBalance, setHouseBalance] = useState(0);
    const [headWinRate, setHeadWinRate] = useState(0);
    const [tailWinRate, setTailWinRate] = useState(0);
    const [recentFlipSides, setRecentFlipSides] = useState<any[]>([]);
    const [livePlayers, setLivePlayers] = useState(0);

    useEffect(() => {
        const interval = setInterval(async () => {
            fetch();
            const house = await getBalanceOfWalletId(HouseWallet);
            setHouseBalance(house);
        }, INTERVAL_RATE);

        return () => clearInterval(interval); // Clean up the interval
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (recentCoinFlips && recentCoinFlips.length > 0) {
            const uniqueRecentCoinFlips = getUniqueObjects(recentFlipSides, recentCoinFlips);
            setRecentFlipSides([
                ...[...uniqueRecentCoinFlips].map(flip => {
                    let sideObj = { id: flip.id, flip: flip.side, walletId: flip.walletId, old: recentFlipSides?.length === 0 };
                    if (!flip.won) sideObj.flip = flip.side === 'H' ? 'T' : 'H';
                    return sideObj;
                }),
                ...recentFlipSides.map(sideObj => ({ ...sideObj, old: true }))
            ]);

            const headWins = recentCoinFlips.filter(flip => (flip.won && flip.side === 'H') || (!flip.won && flip.side === 'T')).length;
            const headRate = Math.round((headWins / recentCoinFlips.length) * 100);
            setHeadWinRate(headRate);

            const tailWins = recentCoinFlips.filter(flip => (flip.won && flip.side === 'T') || (!flip.won && flip.side === 'H')).length;
            const tailRate = Math.round((tailWins / recentCoinFlips.length) * 100);
            setTailWinRate(tailRate);

            const uniquePlayerIds = new Set(recentCoinFlips.map(flip => flip.walletId));
            const uniquePlayers = Array.from(uniquePlayerIds);
            setLivePlayers(uniquePlayers.length);
        }
        // eslint-disable-next-line
    }, [recentCoinFlips]);

    const LiveBarProgress = ({ heads, tails }: any) => {
        return (
            <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="bottom"
                overlay={
                    <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                        <div className="card">
                            <div className="card-body">
                                LAST 100 FLIPS
                            </div>
                        </div>
                    </Popover>}
            // <Tooltip id="button-tooltip-2">LAST 100 FLIPS</Tooltip>}
            >
                <ProgressBar className="rounded-0">
                    <ProgressBar animated={true} className="progress-bar-head" now={heads} label={`HEADS ${heads}%`} key={1} />
                    <ProgressBar animated={true} className="progress-bar-tail" now={tails} label={`TAILS ${tails}%`} key={2} />
                </ProgressBar>
            </OverlayTrigger>
        )
    };

    const LiveDataBarContent = ({ isEnabled }: any) => {

        const getImageForFlip = (flipSide: string) => {
            return flipSide === 'H' ? HeadDataImage : TailDataImage;
        };

        return (

            <div className={`container-fluid flip-container ${isEnabled ? '' : 'd-none'}`}>
                <div className="live-data-bar row d-flex" style={{ zIndex: 10 }}>
                    <div className="col-3 col-sm-3 col-lg-3 col-xl-2 d-none d-sm-inline-flex justify-content-start lh-special nowrap nooverflow">
                        <div className="d-flex">
                            <i className="fa-solid fa-user-group mt-1 live-data-icon" />
                            {
                                livePlayers > 0 && <>
                                    <div className="ms-1 ms-lg-2">
                                        {livePlayers}
                                    </div>
                                </>
                            }

                            <div className="d-none d-sm-inline-flex ms-1 fs--2">
                                FLIPPING
                            </div>
                        </div>

                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            overlay={
                                <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                                    <div className="card">
                                        <div className="card-body">
                                            Current House Balance: This number shows the house's profit (+) or loss (-) in SOL since the last house reset.
                                        </div>
                                    </div>
                                </Popover>}
                        >
                            <div className="d-flex ms-2 ms-lg-3">
                                <i className="fa-solid fa-house-chimney mt-1 live-data-icon" />
                                {
                                    houseBalance > 0 && <>
                                        <div className="ms-1 ms-lg-2">
                                            {houseBalance < DEFAULT_HOUSE_SOL ?
                                                <span style={{ color: '#e03e4d' }}>{customRound(houseBalance - DEFAULT_HOUSE_SOL)}</span> :
                                                <span style={{ color: '#21bd75' }}>+{customRound(houseBalance - DEFAULT_HOUSE_SOL)}</span>
                                            }
                                        </div>
                                    </>
                                }
                                <div className="d-none d-sm-inline-flex ms-1 fs--2">
                                    SOL
                                </div>
                            </div>
                        </OverlayTrigger>
                    </div>
                    <div className="col-12 col-sm-9 col-lg-5 col-xl-7 d-flex nowrap nooverflow">
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            overlay={
                                <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                                    <div className="card">
                                        <div className="card-body">
                                        Last Flips: Each coin shows the latest global flip result. Your flips are highlighted in orange, with the newest flips on the left.
                                      </div>
                                    </div>
                                </Popover>}
                        >
                            <span className="lh-special">LAST FLIPS</span>

                        </OverlayTrigger>
                        {
                            recentFlipSides.length > 0 &&
                            <span className="d-inline-flex">
                                {recentFlipSides.map((item, index) => <div key={item.id} className={`${index === 0 ? 'latest-flip-image ' : ''} `}>
                                    <img
                                        className={`flip-image 
                                    ${item.old ? '' : 'fade-in'}
                                    ${item.walletId === wallet?.publicKey?.toString() ? ' rounded-circle border-green' : ''}                           
                                  }`
                                        } // Add class conditionally

                                        src={getImageForFlip(item.flip)} alt={item.flip} />
                                </div>)}
                            </span>
                        }
                        {
                            !recentFlipSides?.length &&
                            <span className="w-100 ms-3 my-auto">
                                <div className="placeholder-glow">
                                    <span className="placeholder col-12"></span>
                                </div>
                            </span>                                                       
                        }
                    </div>
                    <div className="col-12 col-lg-4 col-xl-3 g-0 gx-lg-3 d-flex">
                        {
                            (headWinRate > 0 || tailWinRate > 0) &&
                            <LiveBarProgress heads={headWinRate} tails={tailWinRate} />
                        }
                        {
                            headWinRate === 0 && tailWinRate === 0 &&
                            <span className="w-100 ms-1 my-auto">
                                <div className="placeholder-glow">
                                    <span className="placeholder col-12"></span>
                                </div>
                            </span>
                        }
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {<LiveDataBarContent isEnabled={liveFeed === true} />}
        </>
    );
};

export default LiveDataBar;
// import useSWR from "swr";
// import { useEffect, useState } from "react";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import { SocialLinks } from "../../components/SocialLink";
// import { fetcher } from "../../utils/fetcher";

interface SocialProps {
    social: JSX.Element | any;
}

// const uri = "https://api.solscan.io/chaininfo";
// const SLOW_INTERVAL = 42000;
// const NETWORK_DOWN_TPS = -1;

const InfoBar = ({ social }: SocialProps) => {
    // const [tps, setTps] = useState(0);
    // const { data, error } = useSWR(
    //     uri,
    //     fetcher,
    //     {
    //         revalidateIfStale: false,
    //         revalidateOnFocus: false,
    //         revalidateOnReconnect: false,
    //         refreshInterval: SLOW_INTERVAL
    //     }
    // );

    // useEffect(() => {
    //     if (!error && !!data) {
    //         const newTps = data?.data?.networkInfo?.tps ?? NETWORK_DOWN_TPS;
    //         setTps(newTps);
    //     }
    //     // eslint-disable-next-line
    // }, [data, error]);

    return (
        <div className="social-icons-bottom-left">
            <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
                <div className="mb-3 mb-lg-0 d-flex flex-column shortcut-row text-center text-lg-start">
                    <small>
                        <SocialLinks social={social}></SocialLinks>
                        {/*
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="top"
                            overlay={
                                <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                                    <div className="card-body">
                                        {
                                            tps > 1500 &&
                                            <>
                                                <i className="fa fa-circle me-2 text-success" aria-hidden="true"></i>
                                                Solana Network Online
                                            </>
                                        }
                                        {
                                            tps < 1500 && tps > 0 &&
                                            <>
                                                <i className="fa fa-circle me-2 text-warning" aria-hidden="true"></i>
                                                Solana Network Congested
                                            </>
                                        }
                                        {
                                            !tps &&
                                            <>
                                                <i className="fa fa-circle me-2 text-danger" aria-hidden="true"></i>
                                                Solana Network Down
                                            </>
                                        }

                                    </div>
                                </Popover>}
                        >
                            <span>
                                Solana Network: {tps.toFixed(0)} TPS {tps < 1500 ? <i className="fa fa-circle" aria-hidden="true"></i> : <></>}
                            </span>
                        </OverlayTrigger>*/}

                    </small>
                    <a href="https://licensing.gaming-curacao.com/validator/?lh=38267b203089ced2234402802b99cfdf" target="_blank" rel="noreferrer" className="justify-content-center d-flex">
                        <img
                            src="https://licensing.gaming-curacao.com/validator/images/Gaming-Curacao-ClickToVerify.png"
                            width="42" height="16" alt="Gaming Curacao: Click to Validate" />
                    </a>
                </div>
            </div>
        </div >
    );
}
export default InfoBar;
import { DateTime } from "luxon";
import { loadState } from "./localStorage";
import { EventTimes } from "./constants";

export const rando = (len = 7) => (Math.random() + 1).toString(36).substring(len);

export const randoSig = () => [...Array(8)].reduce(function (pV) {
    if (!pV?.length) return rando(2);
    return pV?.concat(rando(2));
}, []);

export const memoize = (id, amount, side) => {
    let memo = `id=${id} amount=${amount} side=${side}`;
    return Buffer.from(memo, "utf-8");
};
export const getSideName = (side) => {
    if (side === "H") return "Heads";
    return "Tails";
}

export function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}


export const shouldVersionTransaction = (walletName = loadState('walletName')) =>
    ['Phantom'].includes(walletName) ||
    ['Backpack'].includes(walletName) ||
    ['Slope'].includes(walletName) ||
    ['Solflare'].includes(walletName) ||
    !loadState('ledgerEnabled');


export const isPromoFlagEnabled = () =>
    process.env.REACT_APP_PROMO_FLAG === 'true';

export const isPromoActive = () => {
    const now = DateTime.utc();

    return EventTimes.some(time => {
        const eventStart = DateTime.fromISO(time[0], { zone: 'utc' });
        const eventEnd = DateTime.fromISO(time[1], { zone: 'utc' });
        return now >= eventStart && now <= eventEnd;
    });
};
import React, { useContext, useEffect, useState } from "react"
import { SocialLinks } from "../../components/SocialLink";
import { CommunityContext } from "../../contexts/community.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { constants } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";

const uri = "https://api.solscan.io/chaininfo";
const SLOW_INTERVAL = 3000;
const NETWORK_DOWN_TPS = -1;

export const NetworkDown = () => {
  const { style } = useContext(StyleThemeContext);
  const { community } = useContext(CommunityContext);
  const navigate = useNavigate();
  const [tps, setTps] = useState(-1);
  const { data, error } = useSWR(
    uri,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: SLOW_INTERVAL
    }
  );

  useEffect(() => {
    console.log(data);
    if (!error && !!data) {
      const newTps = data?.data?.networkInfo?.tps ?? NETWORK_DOWN_TPS;
      console.log(`solana network tps: ${newTps}`);
      if (newTps !== NETWORK_DOWN_TPS) redirect(newTps);
      setTps(newTps);
    }
    // eslint-disable-next-line
  }, [data, error]);

  const redirect = (newTps) => {
    if (newTps !== NETWORK_DOWN_TPS && tps !== NETWORK_DOWN_TPS) {
      navigate("../", { replace: true });
      console.log(`old tps: ${tps}`);
    }
  }

  return (
    <React.Fragment>
      <div className={style}>
        <div className="text-center body-wrapper h-100vh h-100 main-header">
          <div className="play step1 mt-md-5 pt-md-5 pt-4">
            <div className="form-signin text-center">
              <h3>SOLANA NETWORK IS OFFLINE</h3>
              <img className="img-fluid" src={constants.BUTTONS.Processing} alt="dcf logo" />
              <h5>Please try again later.</h5>
              <small className={tps < 1500 ? "text-danger" : ""}>
                <SocialLinks social={community}></SocialLinks>
                Solana Network: {tps.toFixed(0)} TPS {tps < 1500 ? <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> : <></>}
              </small>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
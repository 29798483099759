import { useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export const PointsCounter = ({ promo, fragments, authToken, fetchPromo }: any) => {

    useEffect(() => {
        const interval = setInterval(async () => {
          if (promo) {
            fetchPromo(promo.promoId, authToken)
          }
        }, 5000); // Switch every 3 seconds
        return () => clearInterval(interval); // Clean up the interval
        // eslint-disable-next-line
      }, []);

    return (
        <>
            <h5 className={"mt-1 balance-text mb-0 " + (isMobile ? " me-1" : "")}>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                            <div className="card">
                                <div className="card-body">
                                    Accumulate points as you play—everlasting and ready for future DCF ecosystem surprises.
                                </div>
                            </div>
                        </Popover>}
                >
                    <span>
                        {Math.floor(fragments)} POINTS
                    </span>
                </OverlayTrigger>
            </h5>
        </>
    );
};

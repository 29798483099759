import { PublicKey } from "@solana/web3.js";

const AUTHORITY_ADDRESS = process.env.REACT_APP_AUTHORITY_ADDRESS ?? 'modn84SAs1ccUAmxtmRY85yPz44qixgGrUwi276WYy1';

export const HOUSE_TREASURY = 'house_treasury';
export const HOUSE_STATE = 'house_state';
export const DEGENERATE = 'degenerate';
export const REWARDS = 'rewards';
export const DEGEN_COIN_FLIP = 'degen_coin_flip';
// export const DEGEN_COIN_FLIP_PROGRAM_ID = new PublicKey(
//   '3r2St9LGBnXMxv2msAZ4eGAPFNHwpNMbudkdBxdZCCRL',
// );

// export const INITIALIZER_ID = new PublicKey(
//   'DAdbcE5SdqoQArf7V87pYR6jPNmTtEUbR2XeC1kAzZjh',
// );

// export const AUTHORITY_ID = new PublicKey(
//   'B3tvcC35ehGUtLhb68K7YXyfn5pY8YbLttV3tsabTKvs',
// );

export const FUNDS_ID = new PublicKey(
  `9ACFS2Ekes8y1tFPy2toMkdVCquJWMZ3XuESphppmf6E`
);

export const MEMO_ID = new PublicKey(
  "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
);

export const PREORDER_ID = new PublicKey(
  "819VSjbvCvp9YpnKJAe2NCGTzyFajf5fwhBm5uYvhfor"
);

export const DEGEN_COIN_FLIP_PROGRAM_ID = new PublicKey(
  'BmjJ85zsP2xHPesBKpmHYKt136gzeTtNbeVDcdfybHHT',
);

export const INITIALIZER_ID = new PublicKey(
  'h2oMkkgUF55mxMFeuUgVYwvEnpV5kRbvHVuDWMKDYFC',
);

export const AUTHORITY_ID = new PublicKey(
  AUTHORITY_ADDRESS,
);

export const COLD_HOUSE_ID = new PublicKey(
  'i821bbVqQguuDLQp72gNWd52KBXBcEAQc4sVtZxWk4n',
);
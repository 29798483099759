import { useWallet } from "@solana/wallet-adapter-react";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { queryCoinFlipsByWallet } from "../../api/queries.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { FlipRow } from "../FlipRow";
import { FlipRowPlaceholder } from "../FlipRowPlaceholder";

export function MyFlipsModal({ styleCss, ...props }) {
    const wallet = useWallet();
    const { auth } = useContext(AuthorizationContext);
    const [recentFlips, setRecentFlips] = useState([]);

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    const fetch = async () => {
        const now = DateTime.utc();
        const startTime = now.minus({ days: 7 }).toISO();
        const endTime = now.toISO();
        const limit = 100;
        const flips = await queryCoinFlipsByWallet(wallet.publicKey.toString(), startTime, endTime, limit, auth?.idToken)
        setRecentFlips(flips);
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `fixthislater light-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className="pt-4 px-4">
                        <div className="d-flex justify-content-between">
                            <h5 className="mb-0">MY RECENT FLIPS</h5>
                            <CloseButton onClick={props.onHide} />
                        </div>
                    </div>
                    <div className={"card-body px-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '620px', overflowY: 'scroll' }}>
                        <div className="d-flex justify-content-between mt-3 text-success">
                            <h6>LAST&nbsp;WEEK</h6>
                            <hr className="w-100 mt-2" />
                        </div>
                        <ul className="list-group mt-1 border mb-4">
                            {
                                recentFlips?.slice(0, 12)?.map((flip) => {
                                    return (
                                        <FlipRow
                                            flip={flip}
                                            type=''
                                            key={flip.id}
                                            defaultProfilePicture={`https://i.imgur.com/FjUZXhp.png`}></FlipRow>
                                    )
                                })
                            }
                            {
                                !recentFlips?.length && new Array(10).fill(null).map((_, i) => {
                                    return <FlipRowPlaceholder key={i} n={i} />
                                })
                            }
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

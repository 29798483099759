import { get, post } from "../utils/http.util";

const BASE_URL = 'https://quote-api.jup.ag/v6/';

export const quoteGet = async ({
    inputMint,
    outputMint,
    amount,
    maxAccounts
}: any) => {
    const url = `${BASE_URL}/quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${amount}&maxAccounts=${maxAccounts}`
    const response = await get(url);
    return response;
}

export const swapInstructionsPost = async ({
    swapRequest
}: any) => {
    const url = `${BASE_URL}/swap-instructions`
    const response = await post(url, { ...swapRequest });
    return response?.data;
}

export const swapPost = async ({
    swapRequest
}: any) => {
    const url = `${BASE_URL}/swap`
    const response = await post(url, { ...swapRequest });
    return response?.data;
}

// import NavBar from "../../components/ArcadeNavBar";
import ArcadeNavBar from "arcade-navbar";

const NavBar: any = ArcadeNavBar;

/* eslint-disable */
const SocialBar = () => {
    return (
        <div className="fixed-br">
            <NavBar />
        </div>
    );
}

export default SocialBar;
import { useContext, useState } from "react";
import { customSignMessage } from "../../api-smart-contracts/dcf";
import { AudioContext } from "../../contexts/audio.context";
import { CommunityContext } from "../../contexts/community.context";
import { CoinSides } from "../../utils/constants";
import CoinFlipWizardVirtualStepOne from "./CoinFlipWizardVirtualStepOne";
import CoinFlipWizardVirtualStepThree from "./CoinFlipWizardVirtualStepThree";
import CoinFlipWizardVirtualStepTwo from "./CoinFlipWizardVirtualStepTwo";
import { confetti } from '../../utils/confetti';
import { useWallet } from "@solana/wallet-adapter-react";
import { UserContext } from "../../contexts/user.context";
import { RegionRestrictedModal } from "../modals/RegionRestricted.js";
import { StyleThemeContext } from "../../contexts/style-theme.context";

const PAGES = ["zero", "one", "two", "three"];
const confettiAnimation: any = confetti;

const CoinFlipWizardVirtualStatuses = {
    WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
    FLIPPING: "FLIPPING"
};

const CoinFlipWizardVirtualContainer = () => {
    const { community } = useContext(CommunityContext);
    const { style } = useContext(StyleThemeContext);
    const { play } = useContext(AudioContext);
    const { restricted, countryName } = useContext(UserContext);
    const wallet = useWallet();

    const [side, setSide] = useState(CoinSides.HEADS);
    const [state, setState] = useState<any>({
        page: PAGES[1],
        won: false,
        status: null
    });

    const [showModal, setShowModal] = useState(restricted);
    const handleModalClose = () => setShowModal(false);

    const playConfettiAnimation = (defaultTimeout = 5000, min: any = null, max: any = null) => {
        confettiAnimation?.start(defaultTimeout, min, max);
        setTimeout(function () {
            confettiAnimation?.stop()
        }, defaultTimeout);
    };

    const showCoinFlipResult = () => {
        setState({
            ...state,
            page: PAGES[2],
            status: CoinFlipWizardVirtualStatuses.FLIPPING
        })

        const showResult = () => {
            const won = (CoinSides.HEADS === side) === (Math.random() < 0.5);
            if (won) {
                playConfettiAnimation(3000, 0, 10);
                play(2);
            }
            else {
                play(0);
            }

            setState({
                ...state,
                page: PAGES[3],
                won
            });
        }

        setTimeout(showResult, 2100);

    }

    const flipCoin = async (side: any) => {
        setState({
            ...state,
            page: PAGES[2],
            status: CoinFlipWizardVirtualStatuses.WAITING_FOR_APPROVAL
        })

        try {
            if (wallet?.connected) {
                await customSignMessage(`I LIKE ${side === CoinSides.HEADS ? "HEADS" : "TAILS"}`);
            }
            setTimeout(showCoinFlipResult, 2100);

        } catch (e) {
            console.log(e);
            goPageOne();
            return;
        }

    }

    const goPageOne = () => {
        setState({
            ...state,
            page: PAGES[1]
        });
    }

    return (
        <div className={"text-center d-flex main-header h-100vh"}>
            {
                state.page === PAGES[1] &&
                <CoinFlipWizardVirtualStepOne
                    community={community}
                    restricted={restricted}
                    side={side}
                    setSide={setSide}
                    onFlip={() => flipCoin(side)} />
            }
            {
                state.page === PAGES[2] &&
                <CoinFlipWizardVirtualStepTwo
                    side={side}
                    status={state.status} />
            }
            {
                state.page === PAGES[3] &&
                <CoinFlipWizardVirtualStepThree
                    side={side}
                    won={state.won}
                    onBack={goPageOne} />
            }
            {
                showModal &&
                <RegionRestrictedModal
                    show={showModal}
                    walletId={wallet?.publicKey?.toString()}
                    styleCss={style}
                    countryName={countryName}
                    onHide={() => handleModalClose()}
                />
            }
        </div>
    );
};

export default CoinFlipWizardVirtualContainer;
import { constants } from "../../utils/constants";
import InsuranceProgressBar from "./InsuranceProgressBar";

const InsuranceResult = ({ isDarkMode, coinOp, claimReward }) => (
    <>

        <img
            className="img-fluid"
            src={isDarkMode ? 'https://i.imgur.com/rM7gRLw.png' : `https://i.imgur.com/sXRhmwW.png`}
            alt="Insurance" />
        <h4 className="justify-content-center d-flex">CONGRATS!</h4>
        <div className="d-flex justify-content-center mb-3">
            <InsuranceProgressBar
                choice={coinOp?.choice}
                mainGameHistory={coinOp?.mainGameHistory}
                size='l'
                direction='horizontal'
                onTapped={null}
            />
        </div>
        <h3 className="text-success d-flex justify-content-center mb-3">
            YOU WON {coinOp?.winAmount} SOL
        </h3>
        <img onClick={() => claimReward()} className="cursor-pointer double-or-nothing-button" src={constants.BUTTONS.ClaimReward} alt="claim reward" width="100%" height="100%" />
        {/* <Button className="w-100 rounded-pill color-gold" variant="dark" type="button" onClick={() => claimReward()}>CLAIM REWARD</Button> */}
    </>
);

export default InsuranceResult;
import { Modal } from "react-bootstrap";


// const SEE_SHOP = "https://i.imgur.com/xTiMPBW.png";
const PROMO = "https://i.imgur.com/uDGFg56.png";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
};

export function PromoEventModal({ walletId, styleCss, ...props }) {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={`bg-transparent border-0`}
      centered
    >
      <Modal.Body className={"p-0 bg-transparent border-0"}>
        <div className="">
          <button id="x" onClick={() => props.onHide() }>
            <i className={"fa-regular fa-lg fa-circle-xmark"}></i>
          </button>
          <div>
            <img src={PROMO} alt="banner" className="img-fluid rounded-3"></img>
          </div>
          {/* <div className="d-flex justify-content-center text-light mt-3">
            <button className="btn btn-block btn-light" onClick={() => window.open("https://degencatgpt.com", "_blank")}>
            Use your scrolls @ degencatgpt.com
            </button>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

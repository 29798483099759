import { useState, useEffect } from "react";

const BASE_URL = `https://degen-lullaby.s3.us-east-2.amazonaws.com`;

const urls = [
  `0.wav`,
  `1.wav`,
  `2.wav`,
  `3.wav`,
  `4.wav`,
  `5.wav`,
  `6.wav`,
  `7.wav`,
  `8.mp3`,
  `9.wav`,
  `10.wav`,
  `11.wav`,
  `12.m4a`,
  `13.wav`,
  `1up.mp3`, // 14
  `bonus-1.mp3`, // 15 red coin 1
  `bonus-2.mp3`, // 16 red coin 2
  `bonus-3.mp3`, // 17 red coin 3
  `18.mp3`, // 18 star appears,
  `19.mp3`, // 19 starman chill,
  `streaks/streak_sfx_1.mp3`, // 20
  `streaks/streak_sfx_2.mp3`, // 21
  `streaks/streak_sfx_3.mp3`, // 22
  `streaks/streak_sfx_4.mp3`, // 23 X NO
  `streaks/streak_sfx_5.mp3`, // 24
  `streaks/streak_sfx_6.mp3`, // 25
  `streaks/streak_sfx_7.mp3`, // 26
  `streaks/streak_sfx_8.mp3`, // 27
  `streaks/streak_sfx_9.mp3`, // 28
  `streaks/streak_sfx_10.mp3`, // 29
  `streaks/streak_sfx_11.mp3`, // 30
  `streaks/streak_sfx_12.mp3`, // 31
  `streaks/streak_sfx_13.mp3`, // 32
  `streaks/streak_sfx_14.mp3`, // 33
  `streaks/streak_sfx_15.mp3`, // 34
  `streaks/streak_sfx_16.mp3`, // 35
  `streaks/streak_sfx_17.mp3`, // 36
  `streaks/streak_sfx_18.mp3`, // 37
  `streaks/streak_sfx_19.mp3`, // 38
  `streaks/streak_sfx_20.mp3`, // 39
  `streaks/streak_sfx_21.mp3`, // 40
  `streaks/streak_sfx_22.mp3`, // 41
  `streaks/streak_sfx_23.mp3`, // 42
  `streaks/streak_sfx_24.mp3`, // 43
  `streaks/streak_sfx_25.mp3`, // 44
  `streaks/streak_sfx_26.mp3`, // 45
  `streaks/Next/next_streak_sfx_1.mp3`, // 46
  `streaks/Next/next_streak_sfx_2.mp3`, // 47
  `streaks/Next/next_streak_sfx_3.mp3`, // 48
  `streaks/Next/next_streak_sfx_4.mp3`, // 49
  `streaks/Next/next_streak_sfx_5.mp3`, // 50 X NOT EXISTS
  `streaks/Next/next_streak_sfx_6.mp3`, // 51
  `streaks/Next/next_streak_sfx_7.mp3`, // 52
  `streaks/Next/next_streak_sfx_8.mp3`, // 53
  `streaks/Next/next_streak_sfx_9.mp3`, // 54
  `streaks/Next/next_streak_sfx_10.mp3`, // 55
  `streaks/Next/next_streak_sfx_11.mp3`, // 56
  `streaks/Next/next_streak_sfx_12.mp3`, // 57
  `streaks/Next/next_streak_sfx_13.mp3`, // 58
  `streaks/Next/next_streak_sfx_14.mp3`, // 59
  `streaks/Next/next_streak_sfx_15.mp3`, // 60
  `streaks/Next/next_streak_sfx_16.mp3`, // 61
  `streaks/Next/next_streak_sfx_17.mp3`, // 62 x NOT USED IN STREAKS
  `streaks/Next/next_streak_sfx_18.mp3`, // 63
  `streaks/Next/next_streak_sfx_19.mp3`, // 64
  `streaks/Next/next_streak_sfx_20.mp3`, // 65
  `streaks/Next/next_streak_sfx_21.mp3`, // 66 x OLD ORIGAMI TO DELETE
  `streaks/Next/next_streak_sfx_22.mp3`, // 67
  `streaks/Next/next_streak_sfx_23.mp3`, // 68,
  `streaks/Next/super-streak.mp3`, // 69,
  `sidebets/1dub.wav` ,// 70,
  `sidebets/coin_series.wav`, // 71,
  `sidebets/coin_drop.wav`, // 72,
  `sidebets/death.wav`, // 73
];

const audioSources =
  urls.map(file => {
    const url = `${BASE_URL}/${file}`;
    const audio = new Audio(url);
    audio.volume = 0.4;
    audio.oncanplaythrough = false;
    return {
      url,
      audio,
    }
  });

export const useMultiAudio = () => {
  const [sources] = useState(audioSources);
  const [players, setPlayers] = useState(
    urls.map(url => {
      return {
        url,
        playing: false,
      }
    }),
  )

  const toggle = (targetIndex) => {
    const newPlayers = [...players]
    const currentIndex = players.findIndex(p => p.playing === true)
    if (currentIndex !== -1 && currentIndex !== targetIndex) {
      newPlayers[currentIndex].playing = false
      newPlayers[targetIndex].playing = true
    } else if (currentIndex !== -1) {
      newPlayers[targetIndex].playing = false
    } else {
      newPlayers[targetIndex].playing = true
    }
    setPlayers(newPlayers)
  }

  const toggleBg = (targetIndex) => {
    const newPlayers = [...players]
    const currentIndex = players.findIndex(p => p.playing === true)
    if (currentIndex !== -1 && currentIndex !== targetIndex) {
      newPlayers[targetIndex].playing = true
    } else if (currentIndex !== -1) {
      newPlayers[targetIndex].playing = false
    } else {
      newPlayers[targetIndex].playing = true
    }
    setPlayers(newPlayers)
  }

  useEffect(() => {
    sources.forEach((source, i) => {
      players[i].playing ? source.audio.play() : source.audio.pause()
    })
  }, [sources, players])

  useEffect(() => {
    sources.forEach((source, i) => {
      source.audio.addEventListener('ended', () => {
        const newPlayers = [...players]
        newPlayers[i].playing = false
        setPlayers(newPlayers)
      })
    })
    return () => {
      sources.forEach((source, i) => {
        source.audio.removeEventListener('ended', () => {
          const newPlayers = [...players]
          newPlayers[i].playing = false
          setPlayers(newPlayers)
        })
      })
    }
    // eslint-disable-next-line
  }, [])

  return [toggle, toggleBg]
}

import { Modal } from "react-bootstrap";

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url) => () => openInNewTab(url)

export function EntryCheckerModal({ styleCss, image, wallet, entries, top, top10, current, ...props }) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card text-center">
                    <h4 className="mt-4">CHANCE TO WIN</h4>
                    <div className="pt-3 d-flex">
                        <img src={image} className="img-fluid mx-auto" style={{ height: '86px' }} alt="..." />
                    </div>
                    <div className="card-body text-center" style={{ maxHeight: '484px', overflow: 'scroll' }}>
                        {
                            current === 0 &&
                            <>
                                {
                                    entries?.map((a, i) => {
                                        return <h6>{i + 1}: {a}</h6>
                                    })
                                }
                            </>
                        }
                        {
                            current === 1 &&
                            <>
                                {
                                    top10?.map((a, i) => {
                                        return <h6>{i + 1}: {a}</h6>
                                    })
                                }
                            </>
                        }
                        {
                            current === 2 &&
                            <>
                                {
                                    top?.map((a, i) => {
                                        return <h6>{i + 1}: {a}</h6>
                                    })
                                }
                            </>
                        }
                    </div>
                    <button className="btn btn-warning" onClick={props.onClose}>CLOSE</button>
                </div>
            </Modal.Body>
        </Modal >
    );
}
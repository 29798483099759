function get_random(list: any) {
    return list[Math.floor((Math.random() * list.length))];
}

const NORMAL_LOSE = 0;
const LOSE_BIG_WIN_STREAK = 9;
const LOSE_WIN_STREAK = 1;

const WHALE_WIN = 10;
const NORMAL_WIN = 2;
const WIN_STREAK_2_3 = 3;
const WIN_STREAK_4_5 = 4;
const WIN_STREAK_6 = 12;

const AUDIOS_7 = [20, 21, 22, 24, 27, 31, 35, 39, 44, 46, 55, 61];
const WIN_STREAK_7 = get_random(AUDIOS_7);

const AUDIOS_8 = [28, 33, 37, 43, 47, 57, 68, 30, 36, 40, 45, 56, 67];
const WIN_STREAK_8 = get_random(AUDIOS_8);

const AUDIOS_9 = [34, 34, 42, 41, 49, 65];
const WIN_STREAK_9 = get_random(AUDIOS_9);

// const AUDIOS_LOSE_7 = [25, 32];
// const LOSE_STREAK_7 = get_random(AUDIOS_LOSE_7);
// const AUDIOS_LOSE_8 = [29, 44];
// const LOSE_STREAK_8 = get_random(AUDIOS_LOSE_8);
// const AUDIOS_LOSE_9 = [60, 64];
// const LOSE_STREAK_9 = get_random(AUDIOS_LOSE_9);
// const AUDIOS_LOSE_10 = [65, 59, 26, 48];
// const LOSE_STREAK_10 = get_random(AUDIOS_LOSE_10);
// const AUDIOS_LOSE_11 = [38, 63, 69]; // COPY OF STREAK 10 - 13
// const LOSE_STREAK_11 = get_random(AUDIOS_LOSE_11);

const STREAK_10 = 63;
const STREAK_11 = 69;
const STREAK_12 = 23;
const STREAK_13 = 38;

export const getUIFX = (
    won: any,
    winStreak: any,
    loseStreak: any,
    prevWinStreak: any,
    isWhale: boolean
) => {
    let audio = isWhale ? WHALE_WIN : NORMAL_WIN, confettiLevel = null;
    const streak = won ? winStreak : loseStreak;

    if (won) {
        if ([2, 3].includes(streak)) { audio = WIN_STREAK_2_3; confettiLevel = [1000, 0, 100]; }
        else if ([4, 5].includes(streak)) { audio = WIN_STREAK_4_5; confettiLevel = [2000, 0, 100]; }
        else if ([6].includes(streak)) { audio = WIN_STREAK_6; confettiLevel = [3000, 50, 150]; }
        else if ([7].includes(streak)) { audio = WIN_STREAK_7; confettiLevel = [3000, 100, 200]; }
        else if ([8].includes(streak)) { audio = WIN_STREAK_8; confettiLevel = [4000, 150, 250]; }
        else if ([9].includes(streak)) { audio = WIN_STREAK_9; confettiLevel = [5000, 200, 300]; }
        else if ([10].includes(streak)) { audio = STREAK_10; confettiLevel = [6000, 250, 350]; }
        else if ([11].includes(streak)) { audio = STREAK_11; confettiLevel = [7000, 300, 400]; }
        else if ([12].includes(streak)) { audio = STREAK_12; confettiLevel = [8000, 350, 450]; }
        else if (13 <= streak) { audio = STREAK_13; confettiLevel = [9000, 400, 500]; }
    } else {
        // if (streak < 7) {
        if (prevWinStreak > 5) audio = LOSE_BIG_WIN_STREAK;
        else if (prevWinStreak > 1) audio = LOSE_WIN_STREAK;
        else audio = NORMAL_LOSE;
        // }
        // else if (streak === 7) audio = LOSE_STREAK_7;
        // else if (streak === 8) audio = LOSE_STREAK_8;
        // else if (streak === 9) audio = LOSE_STREAK_9;
        // else if (streak === 10) audio = LOSE_STREAK_10;
        // else if (streak >= 11) audio = LOSE_STREAK_11;
        // if (streak > 6 && streak < 10) {
        //     confettiLevel = [2000, 50, 150];
        // }
        // if (streak >= 10) {
        //     confettiLevel = [4000, 100, 300];
        // }
    }

    return {
        audio,
        confettiLevel
    };
};

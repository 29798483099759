import { useEffect, useState } from "react";
import { constants } from "../../utils/constants";

const INTERVAL_TIME = 120000;

function formattedNumber(number: any) {
  return number.toLocaleString('en-US');
}
const CoinFlipWizardStepTwoToken = ({
  community,
  currentWinStreak,
  streak,
  side,
  amount,
  tokenAmount,
  status,
  proMode,
  style,
  onRetryFlip
}: any) => {

  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      setStuck(true);
    }, INTERVAL_TIME); // Switch every 3 seconds
    return () => clearInterval(interval); // Clean up the interval
  }, []);

  return (
    <div className="play step2 py-5 py-md-0">
      <div className="form-signin">
        <a href="https://twitter.com/degencoinflip"
          target="_blank"
          rel="noopener noreferrer"
          className={`text-decoration-none d-none`}>
          <h6 className="text-secondary">Powered by DCF Engine</h6>
        </a>
        {
          !stuck &&
          <>
            {streak > 1 && (
              <h3>
                {currentWinStreak > 1 ? 'Congrats!' : ''}
                <br />
                You're on a {streak} {currentWinStreak > 1 ? 'win' : 'lose'} streak
              </h3>
            )}
            <>
              <img
                className={community?.assets?.coinProcessingImageUrlIsSquare ? "coin-flipping-square" : "coin-flipping"}
                src={community?.assets?.coinProcessingImageUrl ?? (!proMode ? constants.BUTTONS.Processing : (style.includes('dark') ? 'https://i.imgur.com/5r8ebpb.gif' : 'https://i.imgur.com/hKob5Xk.gif'))} alt="processing"
              />
              <h3 className="fst-italic">{status?.split('_').join(' ')}<small><span className="dot dot1">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span></small></h3>
              <h3 className="mb-5 mb-lg-0"><u>{side === "H" ? "HEADS" : "TAILS"}</u> FOR <u>{formattedNumber(+tokenAmount)}</u> WIF</h3>
            </>
          </>
        }
        {
          !!stuck &&
          <>
            <h3 className="mb-0">OH SH*T.</h3>
            <img className={community?.assets?.coinSideImageUrl ? "coin-flipping-square" : "coin-flipping"} src={community?.assets?.coinSideImageUrl ?? constants.GIFS.CoinSide} alt="stuck" />
            <h3 className="mb-0 mb-lg-0">COIN IS</h3>
            <h3 className="mb-5 mb-lg-0">STUCK ON THE SIDE</h3>
            <button
              className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
              onClick={() => { setStuck(false); onRetryFlip({ side, amount }) }}>
              RETRY FLIP
            </button>
          </>
        }
      </div>
    </div>
  );
};

export default CoinFlipWizardStepTwoToken;

import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCommunity } from '../api/communities.service';
import { CHALLENGES, PromoBackgrounds, THEMES } from '../utils/constants';
import { loadState, saveState } from '../utils/localStorage';
import { isPromoActive } from '../utils/helpers';

// !temp

const DEFAULT_COMMUNITY: any = isPromoActive() ? { ...CHALLENGES[0], ...PromoBackgrounds } : { ...CHALLENGES[0], backgroundImage: null, backgroundImageMobile: null, themes: ["light", "dark"] };
const COMMUNITY_KEY = "_community";

interface Community {
  id: string | any;
  name: string;
  coinImageUrl: string;
  profileImageUrl: string;
  logoUrl: string;
  backgroundImage: string;
  backgroundImageDark: string;
  backgroundImageWhale: string;
  backgroundImageMobile: string;
  backgroundImageMobileDark: string;
  twitterUrl: string;
  discordUrl: string;
  marketplaceUrl: string;
  dfcUrl: string;
  slug: string;
  themes: string[];
  assets: JSX.Element | any;
  winStreakAssets: any;
}

interface CommunityContextValue {
  community: Community;
  applyCommunity(c: any): any;
};

const CommunityContext = createContext<CommunityContextValue>({
  community: DEFAULT_COMMUNITY,
  applyCommunity() { }
});

const CommunityProvider = (props: any) => {
  const params = useParams();
  const [community, setCommunity] = useState(loadState(COMMUNITY_KEY) ?? DEFAULT_COMMUNITY);

  useEffect(() => {
    const slug = params?.referral?.toLowerCase();
    if (slug?.length) fetchCommunity(slug);
  }, [params]);

  const fetchCommunity = async (slug: any) => {
    const c = await getCommunity(slug);
    if (c && !!c.slug && c.slug !== DEFAULT_COMMUNITY.slug) setCommunity(c);
  }

  const applyCommunity = (c: any) => {
    if (c) {
      setCommunity(c);
      saveState(c, COMMUNITY_KEY);
    } else {
      setCommunity(THEMES.DEFAULT);
      saveState(null, COMMUNITY_KEY);
    }
  }

  return (
    <div>
      <CommunityContext.Provider value={{ community, applyCommunity }}>
        {props.children}
      </CommunityContext.Provider>
    </div>
  )
};

export { CommunityContext, CommunityProvider };
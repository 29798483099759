import React, { useContext } from "react"
import { SocialLinks } from "../../components/SocialLink";
import { CommunityContext } from "../../contexts/community.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { constants } from "../../utils/constants";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const SystemMaintenence = () => {
  const { style } = useContext(StyleThemeContext);
  const { community } = useContext(CommunityContext);

  return (
    <React.Fragment>
      <div className={style}>
        <div className="text-center body-wrapper h-100vh h-100 main-header">
          <div className="play step1 mt-md-5 pt-md-5 pt-4">
            <div className="form-signin text-center">
              <h3>SCHEDULED MAINTENANCE</h3>
              <h5>Game will be back online shortly.</h5>
              <img className="img-fluid" src={constants.BUTTONS.Processing} alt="dcf logo" />
              <h5>Try our new game below!</h5>
              <img
                onClick={() => openInNewTab("https://degencoindozer.com")}
                className="cursor-pointer double-or-nothing-button my-4"
                src={constants.BUTTONS.CoinDozer}
                alt="DEGEN COIN DOZER"
                width="100%"
                height="100%" />
              <SocialLinks social={community}></SocialLinks>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

import { post } from "../utils/http.util";

const RPC_URL = process.env.REACT_APP_RPC_URL ?? 'https://rpc.helius.xyz/?api-key=ec804cb8-e018-4324-a030-82b3249bdd5e';

export const getAssetsByOwner = async (ownerAddress: string) => {
  const response = await post(
    RPC_URL,
    {
      jsonrpc: '2.0',
      id: 'my-id',
      method: 'getAssetsByOwner',
      params: {
        ownerAddress,
        page: 1, // Starts at 1
        limit: 1000,
        displayOptions: {
          showUnverifiedCollections: false,
          showCollectionMetadata: false,
          showGrandTotal: false,
          showFungible: true //return both fungible and non-fungible tokens
        }
      },
    }
  )
  return response?.data?.result?.items;
};





import { get } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_ANALYTICS_API_URL ?? 'https://dev-api.degencointracker.com/v1';

export const getWeeklyVolume = async (startDate: string, Authorization = '') => {
  const url = `${BASE_URL}/volume-leaderboard?startDate=${startDate}`
  const response = await get(url, { Authorization });
  return response?.payload;
}

export const getMyWeeklyVolumeRank = async (Authorization = '') => {
  const url = `${BASE_URL}/volume-leaderboard/me`
  const response = await get(url, { Authorization });
  return response?.payload;
}

//DEFAULT set to 2023 Christmas Promo 
export const getPromoGameVolume = async (walletId: string, Authorization= '') => {
  const url = `${BASE_URL}/promos/XMAS23/game-volume?walletId=${walletId}`;
  const response = await get(url, {Authorization});
  return response?.payload; 
};

import { useEffect, useState } from "react";
import { parseValue } from "../side-bets/helpers";

const CoinIcons = {
    GOLD: "https://i.imgur.com/KzcwYN7.png",
    GREY: "https://i.imgur.com/dxwMjla.png",
    WIN_GOLD: 'https://i.imgur.com/94jnd45.png',
    WIN_GREY: 'https://i.imgur.com/fhBPNiZ.png',
    LOSE_GOLD: 'https://i.imgur.com/8NHj80u.png',
    LOSE_GREY: 'https://i.imgur.com/zkmrp7y.png',
    HEADS_GOLD: 'https://i.imgur.com/lCIXSWT.png',
    HEADS_GREY: 'https://i.imgur.com/LV8Qvj6.png',
    TAILS_GOLD: 'https://i.imgur.com/RZgVinI.png',
    TAILS_GREY: 'https://i.imgur.com/Re5Gjkn.png'
};

const GoldCoins = {
    'H': CoinIcons.HEADS_GOLD,
    'T': CoinIcons.TAILS_GOLD,
    'W': CoinIcons.WIN_GOLD,
    'L': CoinIcons.LOSE_GOLD,
};

const GreyCoins = {
    'H': CoinIcons.HEADS_GREY,
    'T': CoinIcons.TAILS_GREY,
    'W': CoinIcons.WIN_GREY,
    'L': CoinIcons.LOSE_GREY,
};

const InsuranceProgressBar = ({
    choice,
    mainGameHistory,
    size,
    direction,
    showWonCoins,
    isPendingReward,
    onTapped
}) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (!choice) return;

        const { character, number } = parseValue(choice);

        const isSide = ["H", "T"].includes(character);
        const isFailure = isSide
            ? mainGameHistory?.some(x => x.landedSide !== character)
            : mainGameHistory?.some(x => (x.won ? "W" : "L") !== character);

        const currentCoinFlipIndex = mainGameHistory?.length || 0;

        const generateCoin = (index) => {
            if (showWonCoins) return GoldCoins[character];
            if (!isFailure && currentCoinFlipIndex === index) return null;
            if (index >= currentCoinFlipIndex) return isFailure ? CoinIcons.GREY : 0;
            if (isSide && mainGameHistory[index]?.landedSide === character) return GoldCoins[character];
            const resultCharacter = mainGameHistory[index]?.won ? "W" : "L";
            return resultCharacter === character ? GoldCoins[character] : GreyCoins[isSide ? mainGameHistory[index]?.landedSide : mainGameHistory[index]?.won ? "W" : "L"];
        }

        const t = Array.from({ length: number }, (_, index) => generateCoin(index));

        setItems(t);
    }, [choice, mainGameHistory, showWonCoins]);

    return (
        <>
            <div className="d-flex">
                <div onClick={onTapped} className={`avatar-group justify-content-end avatar-group-${direction === 'vertical' ? 'vertical' : 'horizontal'} ${!!onTapped ? 'cursor-pointer' : ''}`}>
                    {items?.map((item, index) => (
                        <div key={index} className={`avatar avatar-${size ?? 'xl'} border border-2 rounded-circle coin-outline`}>
                            {!!item?.length && <img className="rounded-circle" src={item} alt="" />}
                            {!item?.length && <div className="avatar-name rounded-circle font-dcf-heading"><span>{parseValue(choice).character}</span></div>}
                        </div>
                    ))}
                </div>
                {
                    !!onTapped && isPendingReward && <span className="fa-stack fa-2xs ms-2 my-auto" style={{ position: 'absolute', WebkitTransform: items?.length === 2 ? 'translate(48px, -8px)' : 'translate(80px, -8px)' }}>
                        <i className="fa-solid fa-circle fa-stack-2x text-success"></i>
                        <i className={`fas fa-check fa-stack-1x text-white`}></i>
                    </span>
                }
            </div>
        </>
    );
};

export default InsuranceProgressBar;

import { Idl, Program, BN, web3 } from '@project-serum/anchor';
import { getSolanaProvider, ProviderTypes } from "../services/solana";
import idl from '../interfaces/idl_dcf_23.json';
import { LAMPORTS_PER_SOL, SYSVAR_INSTRUCTIONS_PUBKEY, TransactionInstruction, ComputeBudgetProgram, TransactionMessage, VersionedTransaction, Transaction } from '@solana/web3.js';
import { getDegenCoinFlipDegenerateAccount, getDegenCoinFlipHouseState, getDegenCoinFlipHouseTreasury, getDegenCoinFlipRewardsAccount } from '../utils/accounts';
import { INITIALIZER_ID, AUTHORITY_ID, MEMO_ID, COLD_HOUSE_ID } from '../utils/program-constants';
import moize from 'moize';
import { ERRORS } from '../utils/constants';
import { memoize, shouldVersionTransaction } from '../utils/helpers';
import { customRound } from '../components/side-bets/helpers';
import { getPriorityFeeEstimate } from './helius.service';
import { sendAndConfirm } from './jito.service';

const PRIORITY_LEVEL = "VERYHIGH";
const MAX_MARKET_LAMPORTS = 500000;
const DEFAULT_PRIORITY_FEE = 0.0001;

/**
 * Calculates the adjusted priority fee based on the estimated priority fee, maximum market lamports,
 * and the conversion rate of lamports per SOL.
 * 
 * @param {number} estimatedPriorityFee - The estimated priority fee.
 * @param {number} maxMarketLamports - The maximum number of lamports allowed in the market.
 * @param {number} lamportsPerSol - The number of lamports per SOL unit.
 * @returns {number} The adjusted priority fee.
 */
function calculateAdjustedPriorityFee(estimatedPriorityFee: number): number {
  // Determine the lesser of the estimated fee or the market maximum.
  const effectiveFee = Math.min(estimatedPriorityFee, MAX_MARKET_LAMPORTS);

  // Convert the fee to SOL units.
  let adjustedPriorityFee = effectiveFee / LAMPORTS_PER_SOL;

  // return default if 0
  return adjustedPriorityFee <= 0 ? DEFAULT_PRIORITY_FEE : adjustedPriorityFee;
}


const {
  SystemProgram,
  PublicKey,
} = web3;

let programID: any;
let program: any;
let provider: any;

const init = moize((wallet: any = null) => {
  if (program) return;
  programID = new PublicKey(idl.metadata.address);
  provider = getSolanaProvider(wallet, ProviderTypes.PRIMARY);
  program = new Program(idl as Idl, programID, provider);
});

export const depositSol = async (wallet: any, amount: any, id?: any, side?: any, priorityFee = DEFAULT_PRIORITY_FEE) => {
  init(wallet);

  const [_house_treasury_account_pda] = await getDegenCoinFlipHouseTreasury(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_house_state_account_pda] = await getDegenCoinFlipHouseState(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_degenerate_account_pda] = await getDegenCoinFlipDegenerateAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_rewards_account_pda] = await getDegenCoinFlipRewardsAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  let tx;
  try {
    const instruction = await program.instruction.participate(
      new BN(customRound(customRound(amount) * LAMPORTS_PER_SOL)),
      {
        accounts: {
          degenerate: provider.wallet.publicKey,
          initializer: INITIALIZER_ID,
          authority: AUTHORITY_ID,
          coldHouse: COLD_HOUSE_ID,
          houseTreasury: _house_treasury_account_pda,
          houseState: _house_state_account_pda,
          degenerateAccount: _degenerate_account_pda,
          rewardsAccount: _rewards_account_pda,
          systemProgram: SystemProgram.programId,
          instructions: SYSVAR_INSTRUCTIONS_PUBKEY
        }
      }
    );

    const { connection } = provider;
    const { value: { blockhash } } = await connection.getLatestBlockhashAndContext();

    const memoInstruction = new TransactionInstruction({
      keys: [{ pubkey: provider.wallet.publicKey, isSigner: true, isWritable: true }],
      data: memoize(id, amount, side),
      programId: MEMO_ID
    });


    if (!process.env.REACT_APP_RPC_URL?.includes('dev')) {
      return await sendAndConfirm(provider.wallet, connection, memoInstruction, instruction);
    }
    let adjustedPriorityFee = priorityFee;
    if (!adjustedPriorityFee) {
      try {
        const instructions = [memoInstruction, instruction];
        const messageV0 = new TransactionMessage({
          payerKey: provider.wallet.publicKey,
          recentBlockhash: blockhash,
          instructions
        }).compileToLegacyMessage();
        const versionedTransaction = new VersionedTransaction(messageV0);
        const response = await getPriorityFeeEstimate(PRIORITY_LEVEL, versionedTransaction);
        adjustedPriorityFee = calculateAdjustedPriorityFee(response.priorityFeeEstimate as number);
      } catch {
        adjustedPriorityFee = DEFAULT_PRIORITY_FEE;
      }
    }

    console.log('Selected Priority Fee: ' + adjustedPriorityFee);
    const computeBudgetInstruction = ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: Math.round((adjustedPriorityFee * LAMPORTS_PER_SOL) + 1)
    });

    const instructions = [memoInstruction, computeBudgetInstruction, instruction];

    // const x = await generateSwapIx(amount, wallet);
    // console.log(x);

    const messageV0 = new TransactionMessage({
      payerKey: provider.wallet.publicKey,
      recentBlockhash: blockhash,
      instructions
    }).compileToLegacyMessage();

    const versionedTransaction = new VersionedTransaction(messageV0);
    if (shouldVersionTransaction()) {
      const txes = await provider.sendAll([{ tx: versionedTransaction }]);
      tx = txes[0];
    } else {
      const serialized = versionedTransaction.serialize();
      const x = Transaction.from(serialized) as any;
      tx = await provider.send(x);
    }
  }
  catch (e) {
    console.log(e);
    throw ERRORS.DEPOSIT_FAILED;
  }

  return tx;
};


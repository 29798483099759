import axios from "axios";
import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import { PublicKey, SystemProgram, TransactionMessage, VersionedTransaction } from "@solana/web3.js";

const BASE_URL = "https://mainnet.block-engine.jito.wtf:443/api/v1";

const client = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  responseType: 'json',
  headers: { 'Content-Type': 'application/json' },
});

const TIP_ACCOUNTS = [
  '96gYZGLnJYVFmbjzopPSU6QiEV5fGqZNyN9nmNhvrZU5',
  'HFqU5x63VTqvQss8hp11i4wVV8bD44PvwucfZ2bU7gRe',
  'Cw8CFyM9FkoMi7K7Crf6HNQqf4uEMzpKw6QNghXLvLkY',
  'ADaUMid9yfUytqMBgopwjb2DTLSokTSzL1zt6iGPaS49',
  'DfXygSm4jCyNCybVYYK6DwvWqjKee8pbDmJGcLWNDXjh',
  'ADuUkR4vqLUMWXxW9gh6D6L8pMSawimctcNZ5pGwDcEt',
  'DttWaMuVvTiduZRnguLF7jNxTgiMBZ1hyAumKUiL2KRL',
  '3AVi9Tg9Uo68tJfuvoKvqKNWKkC5wPdSSdeBnizKZ6jT',
].map((pubkey) => new PublicKey(pubkey));

const getRandomTipAccount = () =>
  TIP_ACCOUNTS[Math.floor(Math.random() * TIP_ACCOUNTS.length)];

const buildJitoBody = (transaction) => ({
  "jsonrpc": "2.0",
  "id": 1,
  "method": "sendBundle",
  "params": [
    [
      bs58.encode(transaction.serialize())
    ]
  ]
});

const makeBundlesRequest = async (transaction) => {
  const response = await client.post(`/bundles`, buildJitoBody(transaction));
  if (response?.data?.error) {
    throw response?.data?.error;
  }
  return response.data;
};


const executeTransaction = async (signedTransaction, blockhash, lastValidBlockHeight, connection) => {
  try {
    await makeBundlesRequest(signedTransaction);
    const signature = bs58.encode(signedTransaction.signatures[0]);
    const confirmed = (await connection
      .confirmTransaction({
        signature,
        blockhash: blockhash,
        lastValidBlockHeigwht: lastValidBlockHeight
      })).value;

    console.log(confirmed);
    return signature;
  }
  catch (e) {
    console.log(e);
    throw e;
  }
}


export const sendAndConfirm = async (wallet, connection, memoInstruction, instruction) => {

  const tipIxn = SystemProgram.transfer({
    fromPubkey: wallet.publicKey,
    toPubkey: getRandomTipAccount(),
    lamports: 100000,
  });

  const instructions = [tipIxn, memoInstruction, instruction];

  const {
    blockhash, lastValidBlockHeight
  } = await connection.getLatestBlockhash('finalized');

  const messageV0 = new TransactionMessage({
    payerKey: wallet.publicKey,
    recentBlockhash: blockhash,
    instructions,
  }).compileToV0Message();
  const transaction = new VersionedTransaction(messageV0);
  const signedTransaction = await wallet.signTransaction(transaction);
  const jitoTxes = Array.from({ length: 1 }).map((_, index) => {
    console.log(`executing ${index}`)
    return new Promise((resolve, reject) => {
      executeTransaction(signedTransaction, blockhash, lastValidBlockHeight, connection)
        .then(tx => {
          console.log(`resolved ${index}`)
          resolve(tx);
        })
        .catch(err => {
          console.log(`rejected ${index}`)
          reject(err);
        });
    });
  });
  return await Promise.any(jitoTxes)
};

export const sendAndConfirmSerialized = async (wallet,serialized, connection) => {
  const {
    blockhash, lastValidBlockHeight
  } = await connection.getLatestBlockhash('finalized');

  const transaction = VersionedTransaction.deserialize(Buffer.from(serialized, 'base64'));
  const signedTransaction = await wallet.signTransaction(transaction);

  const jitoTxes = Array.from({ length: 1 }).map((_, index) => {
    console.log(`executing ${index}`)
    return new Promise((resolve, reject) => {
      executeTransaction(signedTransaction, blockhash, lastValidBlockHeight, connection)
        .then(tx => {
          console.log(`resolved ${index}`)
          resolve(tx);
        })
        .catch(err => {
          console.log(`rejected ${index}`)
          reject(err);
        });
    });
  });
  return await Promise.any(jitoTxes)
};
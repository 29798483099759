import { useState } from "react";
import { Modal } from "react-bootstrap";

export function RegionRestrictedModal({ styleCss, countryName, ...props }) {
    const [enabled, setEnabled] = useState(false);
    setTimeout(function () {
        setEnabled(true);
    }, 3000);
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')}>
                        <h5 className="">Your IP address appears to be from:</h5>
                        <h4>{countryName}</h4>
                        <br />
                        If you live in the following restricted territories, you are not allowed to play on Degencoinflip's on-chain coinflip game and will instead be directed to degencoinflip.com/try.
                        <br />
                        <br />
                        <p className="text-danger font-weight-bold mb-0">
                            Australia, Aruba, Bonaire, Curacao, France, Netherlands, Saba, Spain, Statia, St Martin, United Kingdom, USA, and/or any other jurisdication that the Central Government of Curacao deems online gambling is illegal.
                        </p>
                    </div>
                    <div className="card-footer text-center">
                        <button
                            className="btn btn-block w-100 btn-lg my-2 rounded-sm btn-warning" disabled={!enabled}
                            onClick={props.onHide}>
                            CONTINUE
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
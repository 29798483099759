import { DateTime } from 'luxon';
import { createContext, useContext, useEffect, useState } from 'react';
import { getUserRiskFreePlays } from '../api/aegis.service';
import { RiskFreeFlipNotificationToast } from '../components/toasts/RiskFreeFlipNotificationToast';
import { AudioContext } from './audio.context';
import { AuthorizationContext } from './auth.context';

interface RiskFreePlaysContextValue {
  riskFreePlays: any;
  getRiskFreePlays(token: string): any;
}

const RiskFreePlaysContext = createContext<RiskFreePlaysContextValue>({
  riskFreePlays: null,
  getRiskFreePlays() { }
});

const RiskFreePlaysProvider = (props: any) => {
  const { auth } = useContext(AuthorizationContext);
  const { play } = useContext(AudioContext);

  const [riskFreePlays, setRiskFreePlays] = useState<any>(null);
  const [showToast, setShowToast] = useState<boolean>(false);

  useEffect(() => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!expired) {
      setTimeout(function () {
        getRiskFreePlays(auth?.idToken);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [auth]);

  const getRiskFreePlays = async (token: string = auth?.idToken) => {
    const payload = await getUserRiskFreePlays(token);
    if (payload?.length) {
      if (!riskFreePlays?.length) {
        play(14);
        setShowToast(true);
      }
    }
    setRiskFreePlays(payload);
  }

  return (
    <div>
      <RiskFreePlaysContext.Provider value={{ riskFreePlays, getRiskFreePlays }}>
        {props.children}
        <RiskFreeFlipNotificationToast show={showToast} onClose={() => setShowToast(false)}></RiskFreeFlipNotificationToast>
      </RiskFreePlaysContext.Provider>
    </div>
  )
};

export { RiskFreePlaysContext, RiskFreePlaysProvider };

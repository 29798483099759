

import { get, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_AEGIS_API_URL ?? 'https://dev-api.degenaegis.com/v1';

export const getUserRiskFreePlays = async (Authorization = '') => {
  const url = `${BASE_URL}/risk-free-plays?status=INTERACTABLE`
  const response = await get(url, { Authorization });
  return response?.payload;
}
export const getRiskFreePlayByReference = async (coinFlipId: string, Authorization = '') => {
  const url = `${BASE_URL}/risk-free-plays?referenceId=${coinFlipId}`
  const response = await get(url, { Authorization });
  return response?.payload;
}

export const activateRiskFreePlay = async (id: string, Authorization = '') => {
  const url = `${BASE_URL}/risk-free-plays/${id}/activate`
  const response = await post(url, { gameId: 'DCF' }, { Authorization });
  return response?.payload;
}

export const deactivateRiskFreePlay = async (id: string, Authorization = '') => {
  const url = `${BASE_URL}/risk-free-plays/${id}/deactivate`
  const response = await post(url, {}, { Authorization });
  return response?.payload;
}

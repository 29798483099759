export const Tabs = {
    HEADTAILS: 'headtails',
    WINLOSS: 'winloss',
};

export const NavTabs = {
    SIDE_BETS: 'sidebets',
    HISTORY: 'history',
};

export const DegenCoinFlipSideBets = {
    H3: 'H3',
    H5: 'H5',
    H10: 'H10',
    T3: 'T3',
    T5: 'T5',
    T10: 'T10',
    W3: 'W3',
    W5: 'W5',
    W10: 'W10',
    L3: 'L3',
    L5: 'L5',
    L10: 'L10',
};

export const HeadTailsChoices = [
    { value: DegenCoinFlipSideBets.H3, name: "HEADS", multiplier: "3" },
    { value: DegenCoinFlipSideBets.H5, name: "HEADS", multiplier: "5" },
    { value: DegenCoinFlipSideBets.H10, name: "HEADS", multiplier: "10" },
    { value: DegenCoinFlipSideBets.T3, name: "TAILS", multiplier: "3" },
    { value: DegenCoinFlipSideBets.T5, name: "TAILS", multiplier: "5" },
    { value: DegenCoinFlipSideBets.T10, name: "TAILS", multiplier: "10" },
];

export const WinLossChoices = [
    { value: DegenCoinFlipSideBets.W3, name: "WIN", multiplier: "3" },
    { value: DegenCoinFlipSideBets.W5, name: "WIN", multiplier: "5" },
    { value: DegenCoinFlipSideBets.W10, name: "WIN", multiplier: "10" },
    { value: DegenCoinFlipSideBets.L3, name: "LOSS", multiplier: "3" },
    { value: DegenCoinFlipSideBets.L5, name: "LOSS", multiplier: "5" },
    { value: DegenCoinFlipSideBets.L10, name: "LOSS", multiplier: "10" },
];

import { constants } from "../../utils/constants";
import ImageToggleButton from "../ImageToggleButton";

// const WHALE_AMOUNTS = [1, 2, 3, 4, 5, 10];
// const randomAmount = (ignorePrice: any) => {
//   const amounts = WHALE_AMOUNTS.filter((n) => n !== ignorePrice);
//   return amounts[Math.floor(Math.random() * amounts.length)];
// };

const CoinFlipWizardStepOneWhale = ({
  community,
  side,
  setSide,
  price,
  setPrice,
  loading,
  onDoubleOrNothing,
  flipCheck,
  rffMessage
}: any) => {
  return (
    <>
      <h3 className="my-2 mt-sm-5">I LIKE</h3>
      <div className="row mb-1">
        <div className="col-6">
          <ImageToggleButton
            name="Heads"
            buttonImageSrc={
              community?.assets?.buttonHeads ?? constants.BUTTONS_WHALE.Heads
            }
            isSelected={side === "H"}
            onSelect={() => setSide("H")}
          />
        </div>
        <div className="col-6">
          <ImageToggleButton
            name="Tails"
            buttonImageSrc={
              community?.assets?.buttonTails ?? constants.BUTTONS_WHALE.Tails
            }
            isSelected={side === "T"}
            onSelect={() => setSide("T")}
          />
        </div>
      </div>
      <h3 className="mb-1">FOR</h3>
      <div className="row mb-1">
        <div className="col-4">
          <ImageToggleButton
            name="3 SOL"
            buttonImageSrc={
              community?.assets?.button3Sol ?? constants.BUTTONS_WHALE.ThreeSol
            }
            isSelected={price === 3}
            onSelect={() => setPrice(3)}
          />
        </div>
        <div className="col-4">
          <ImageToggleButton
            name="4 SOL"
            buttonImageSrc={
              community?.assets?.button4Sol ?? constants.BUTTONS_WHALE.FourSol
            }
            isSelected={price === 4}
            onSelect={() => setPrice(4)}
          />
        </div>
        <div className="col-4">
          <ImageToggleButton
            name="5 SOL"
            buttonImageSrc={
              community?.assets?.button5Sol ?? constants.BUTTONS_WHALE.FiveSol
            }
            isSelected={price === 5}
            onSelect={() => setPrice(5)}
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-4">
          <ImageToggleButton
            name="6 SOL"
            buttonImageSrc={
              community?.assets?.button6Sol ?? constants.BUTTONS_WHALE.SixSol
            }
            isSelected={price === 6}
            onSelect={() => setPrice(6)}
          />
        </div>
        <div className="col-4">
          <ImageToggleButton
            name="8 SOL"
            buttonImageSrc={
              community?.assets?.button8Sol ?? constants.BUTTONS_WHALE.EightSol
            }
            isSelected={price === 8}
            onSelect={() => setPrice(8)}
          />
        </div>
        <div className="col-4">
          <ImageToggleButton
            name="10 SOL"
            buttonImageSrc={
              community?.assets?.button10Sol ?? constants.BUTTONS_WHALE.TenSol
            }
            isSelected={price === 10}
            onSelect={() => setPrice(10)}
          />
        </div>
      </div>
      {flipCheck && !rffMessage?.length && <hr />}
      {!flipCheck && <p className="text-danger p-1 mb-1">
          You do not have enough SOL for this flip.
        </p>}
      {flipCheck && !!rffMessage?.length && <p className="text-danger p-1 mb-1">{rffMessage}</p>}
      {loading && (
        <div className="btn btn-outline-dark disabled btn-block py-2 w-100 mb-1">
          <i className="fas fa-circle-notch fa-spin"></i> FLIPPING
        </div>
      )}
      {!loading && (
        <img
          onClick={onDoubleOrNothing}
          className="cursor-pointer double-or-nothing-button mb-1"
          src={
            community?.assets?.buttonDoubleOrNothing ??
            constants.BUTTONS_WHALE.DoubleOrNothing
          }
          alt="double or nothing"
          width="100%"
          height="100%"
        />
      )}
    </>
  );
};

export default CoinFlipWizardStepOneWhale;

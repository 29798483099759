import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getMyFragments } from "../api/atlantis.service";
import { useInterval } from "../hooks/useInterval";
import { loadState, saveState } from "../utils/localStorage";
import { PromoEventModal } from "./modals/PromoEventModal";

const SLOW_INTERVAL = 10000;
const now = DateTime.utc();
const MODAL_KEY = `${now.month}-${now.year}_FRAGMENTS`;

export const FragmentCounter = ({ walletId, authToken, promo: { startTime, promoId }, style }: any) => {
    const [fragments, setFragments] = useState<number>(0);
    const [cache, setCache] = useState(null);
    const [showJackpotModal, setShowJackpotModal] = useState(false);
    const handleJackpotModalOpen = () => setShowJackpotModal(true);
    const handleJackpotModalClose = () => setShowJackpotModal(false);
    const [newCount, setNewCount] = useState(0);
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (counter < newCount) {
                setCounter(1 + counter);
            }
        }, 42);

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line
    }, [counter, newCount]);

    useEffect(() => {
        if (walletId && walletId !== cache) {
            setCache(walletId);
            fetchPromo();
            if (!loadState(MODAL_KEY) && DateTime.fromISO(startTime, { zone: 'UTC' }) < now) {
                setShowJackpotModal(true);
                saveState(true, MODAL_KEY);
            }
        }
        // eslint-disable-next-line
    }, [walletId, cache]);

    useInterval(async () => {
        if (walletId) fetchPromo();
    }, SLOW_INTERVAL);

    const fetchPromo = async () => {
        const {
            availableFragments,
            pendingFragments
        } = await getMyFragments(promoId, authToken);
        setFragments(availableFragments);
        setNewCount(pendingFragments);
        if (!pendingFragments) setCounter(0);
    }

    return (
        <>
            {
                showJackpotModal &&
                <PromoEventModal
                    show={showJackpotModal}
                    walletId={walletId}
                    styleCss={style}
                    onHide={() => handleJackpotModalClose()}
                />
            }
            <h5 onClick={handleJackpotModalOpen} className={"mt-1 balance-text mb-0 cursor-pointer" + (isMobile ? " me-1" : "")}>
                {fragments}
                {
                    counter > 0 &&
                    <span className="d-inline-flex ms-1">(+{counter})</span>
                }
                <img src="https://imgur.com/KFxS33b.png" className="img-fluid mb-1 ms-1" alt="fragments" style={{ maxWidth: '32px' }} />
            </h5>
        </>
    );
};

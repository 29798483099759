
import { CoinSides, constants, opposite } from "../../utils/constants";

const getCoinLogo = (winner: string) => {
  if (winner === CoinSides.HEADS) {
    return constants.BUTTONS.BabyCoin;
  } else {
    return constants.BUTTONS.BabyCoinButt;
  }
};

const CoinFlipWizardVirtualStepThree = ({
  won,
  side,
  onBack
}: any) => {
  const title = won ? `YOU WON` : `YOU LOST`;

  return (
    <div className="play step2 py-5 py-md-0">
      <div className="form-signin">
        <>
          <img className="logo" src={getCoinLogo(won ? side : opposite(side))} alt="coin" width="256" height="256" />
          <h3 className="mt-3">{title}</h3>
          <h3 className={won ? "text-success mt-0" : "mt-0 text-danger"}><b>{won ? "CONGRATS" : "TRY AGAIN"}</b></h3>
          <hr />
          <img onClick={onBack} className="cursor-pointer double-or-nothing-button" src={won ? constants.BUTTONS.TryAgain : constants.BUTTONS.FlipACoin} alt="Back to Flip" width="100%" height="100%" />
        </>
      </div>
    </div>
  );
};

export default CoinFlipWizardVirtualStepThree;

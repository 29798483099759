import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, TransactionMessage, VersionedTransaction } from "@solana/web3.js";
import { getSolanaProvider } from "./solana"
export const AUTH_WALLET_ID = new PublicKey('6SyT5GFE1wzRfr3je914PTWrkfjUtKw6tEFyHkMFsZao');

const AUTH_AMOUNT = 0.00001;

export const authTransfer = async (wallet: any) => {
    try {
        const provider: any = getSolanaProvider(wallet);

        const tx = new Transaction();
        const instruction = SystemProgram.transfer({
            fromPubkey: provider.wallet.publicKey,
            toPubkey: AUTH_WALLET_ID,
            lamports: +(AUTH_AMOUNT * LAMPORTS_PER_SOL).toFixed(0) //Investing 1 SOL. Remember 1 Lamport = 10^-9 SOL.
        });
        tx.add(instruction);

        const { value: { blockhash } } = await provider?.connection.getLatestBlockhashAndContext();
        const instructions = [instruction];
        const messageV0 = new TransactionMessage({
            payerKey: provider.wallet.publicKey,
            recentBlockhash: blockhash,
            instructions
        }).compileToLegacyMessage();
        const versionedTransaction = new VersionedTransaction(messageV0);
        const vx = await wallet.signTransaction(versionedTransaction);
        return await provider.connection.sendTransaction(vx, {
            maxRetries: 3
        });
    }
    catch (e) {
        console.log(e);
    }
};


import { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { getAssetsByOwner } from "../api/helius.service";

function moveDecimalLeftAndFormat(number: any, decimalPlaces: any) {
    const result = number / Math.pow(10, decimalPlaces);
    return result.toLocaleString('en-US', { minimumFractionDigits: decimalPlaces });
}


const TokenBalanceDisplayTemp = ({ balanceString, setBalanceString, tokenId, clickedChangeAmount, textOnly }: any) => {
    const wallet = useWallet();
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (wallet && wallet.publicKey && tokenId) {
            fetchBalance(wallet.publicKey.toString(), tokenId);
        }
        // eslint-disable-next-line
    }, [wallet, tokenId]);


    useEffect(() => {
        const interval = setInterval(async () => {
            if (wallet?.publicKey?.toString()) {
                fetchBalance(wallet.publicKey.toString(), tokenId);
            }
        }, 10000); // Switch every 3 seconds
        return () => clearInterval(interval); // Clean up the interval
        // eslint-disable-next-line
    }, []);

    const fetchBalance = async (walletId: string, tokenId: string) => {
        const items = await getAssetsByOwner(walletId);
        const [token] = items.filter((i: any) => i.id === tokenId);
        const balance = token?.token_info?.balance;
        const decimals = token?.token_info?.decimals;
        if (balance) {
            setBalance(balance / Math.pow(10, decimals));
            setBalanceString(moveDecimalLeftAndFormat(balance, decimals));
        }
    }

    if (textOnly) {
        return (
            <h5 className="mt-1 balance-text mb-0">
                {(+balanceString).toFixed(0)}<span className="ms-1">WIF</span>
            </h5>
        )
    }

    return (
        <div>
            <small className="me-2">
                <i className="fa fa-wallet fa-2xs me-1" />
                {(+balanceString).toFixed(0)} WIF
            </small>
            <span className="badge rounded-pill bg-secondary me-1 cursor-pointer" onClick={() => clickedChangeAmount(balance / 2)}>HALF</span>
            <span className="badge rounded-pill bg-secondary cursor-pointer" onClick={() => clickedChangeAmount(balance)}>MAX</span>
        </div>
    );
}

export default TokenBalanceDisplayTemp;
import { useEffect, useState } from "react";
import BetAmountButton from "../BetAmountButton";
import { customRound } from "../side-bets/helpers";

// Array of predefined bet amounts including a 'CLEAR' option
const betAmounts = [0.01, 0.1, 0.25, 1, 5, 'CLEAR'];

/**
 * Renders a menu of buttons for selecting bet amounts.
 * 
 * @param {Function} setPrice Function to set the price.
 * @param {Function} clearPrice Function to reset the price to zero.
 */
const ButtonsMenu = ({ setPrice, clearPrice }: any) => {

  /**
   * Renders an individual bet amount button.
   * 
   * @param {number|string} amount The bet amount or 'CLEAR' string.
   * @returns {JSX.Element} The bet amount button component.
   */
  const renderButton = (amount: any) => (
    <div className="col-4" key={amount}>
      <BetAmountButton
        amount={amount}
        onSelect={() => amount === 'CLEAR' ? clearPrice(0) : setPrice(amount)}
      />
    </div>
  );

  return (
    <>
      <div className="row mb-1">
        {betAmounts.slice(0, 3).map(renderButton)}
      </div>
      <div className="row mb-1">
        {betAmounts.slice(3).map(renderButton)}
      </div>
    </>
  );
};

/**
 * Renders a button for selecting a side in the coin flip (Heads or Tails).
 * 
 * @param {string} side The current selected side.
 * @param {Function} setSide Function to set the selected side.
 * @param {string} label The label for the button (e.g., 'HEADS', 'TAILS').
 * @param {string} value The value associated with the side ('H' or 'T').
 * @returns {JSX.Element} The side selection button component.
 */
const SideButton = ({ side, setSide, label, value }: any) => (
  <div className="col-6">
    <button
      className={`btn btn-lg fs-3 btn-dark side-button mb-1 w-100 font-buttons rounded-0 d-inline-flex letter-spacing-1 justify-content-center ${side === value ? ' selected' : ''}`}
      onClick={() => setSide(value)}>
      {label}
    </button>
  </div>
);

/**
 * Main component for the first step in the Coin Flip Wizard.
 * Manages state for the side selection, bet amount, and displays messages.
 * 
 * @param {Object} props The component props.
 * @returns {JSX.Element} The rendered component for the first step of the Coin Flip Wizard.
 */
const CoinFlipWizardStepOnePro = ({
  side,
  setSide,
  price,
  setPrice,
  onDoubleOrNothing,
  clearPrice,
  flipCheck,
  rffMessage
}: any) => {
  const [newCount, setNewCount] = useState(0);
  const [counter, setCounter] = useState(0);

  // Effect to update the newCount based on price
  useEffect(() => {
    setNewCount(price);
  }, [price]);

  // Effect to increment counter towards newCount
  useEffect(() => {
    const updateCounter = () => {
      /**
       * Updates the counter state, incrementing towards newCount.
       */
      if (counter < newCount) {
        const difference = newCount - counter;
        const step = difference >= 1 ? 1 : (difference >= 0.1 ? 0.1 : 0.01);
        setCounter(customRound(counter + step));
      } else {
        setCounter(newCount);
      }
    };

    const timeout = setTimeout(updateCounter, 42);

    // Cleanup function to clear the timeout
    return () => clearTimeout(timeout);
  }, [counter, newCount]);

  return (
    <>
      <h3 className="my-2 mt-sm-4">I LIKE</h3>
      <div className="row mb-1">
        <SideButton side={side} setSide={setSide} label="HEADS" value="H" />
        <SideButton side={side} setSide={setSide} label="TAILS" value="T" />
      </div>
      <h3 className="mb-1">FOR</h3>
      <ButtonsMenu setPrice={setPrice} clearPrice={clearPrice} />
      {!flipCheck ? (
        <p className="text-danger p-1 mb-1">
          You do not have enough SOL for this flip.
        </p>
      ) : (
        <hr />
      )}
      {flipCheck && rffMessage && <p className="text-danger p-1 mb-1">{rffMessage}</p>}
      <button className="fs-2 btn btn-lg btn-dark mb-1 w-100 font-buttons rounded-0 double-or-nothing-button letter-spacing-1" onClick={onDoubleOrNothing}>
        BET {counter.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} SOL
      </button>
    </>
  );
};

export default CoinFlipWizardStepOnePro;

import { useEffect, useState } from "react";
import { fetchQuote } from "../../services/jup.service";
import { MINTS } from "../../utils/constants";
import { customRound } from "../side-bets/helpers";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import useDebounce from "../../hooks/useDebounce";
import TokenBalanceDisplay from "../TokenBalanceDisplay";

const WIF_COLOR = "#ffcca9";

/**
 * Renders a button for selecting a side in the coin flip (Heads or Tails).
 * 
 * @param {string} side The current selected side.
 * @param {Function} setSide Function to set the selected side.
 * @param {string} label The label for the button (e.g., 'HEADS', 'TAILS').
 * @param {string} value The value associated with the side ('H' or 'T').
 * @returns {JSX.Element} The side selection button component.
 */
const SideButton = ({ side, setSide, label, value }: any) => {
  return (
    <div className="col-6">
      <button
        style={{ backgroundColor: WIF_COLOR }}
        className={`btn btn-lg fs-3 btn-dark side-button mb-1 w-100 font-buttons rounded-0 d-inline-flex letter-spacing-1 justify-content-center ${side === value ? ' selected' : ''}`}
        onClick={() => setSide(value)}>
        {label}
      </button>
    </div>
  )
};

const AmountInputForm = ({ solValue, amount, handleChangeAmount, isTyping, style }: any) => {
  return (<div className={`card ${style.includes('dark') ? 'text-light' : 'text-dark'}`}>
    <div className={`card-body rounded text-start pb-2 ${style.includes('dark') ? 'bg-dark' : ''}`}>
      <div className="d-flex justify-content-between mb-1">
        <span>FOR</span>
        <TokenBalanceDisplay tokenId={MINTS.WIF} clickedChangeAmount={(x: any) => handleChangeAmount(x)} />
      </div>
      <div className="p-3 bg-secondary rounded-3 token-form">
        <div className="d-flex">
          <button className="btn-light btn dropdown-toggle1 d-flex me-2" style={{ paddingRight: '2rem', cursor: 'default' }}>
            <img src="https://arweave.net/WI3PL7i6hjXsb-fLsoM1spR0lQNNnwmWNrxESKGM8Rw"
              className="img-fluid me-2 rounded-circle"
              alt="mode"
              style={{ maxHeight: '23px' }}
            />WIF
          </button>
          <div className="input-group">
            <input
              type="number"
              step="0.01"
              value={amount}
              onChange={handleChangeAmount}
              className="form-control text-end"
              placeholder="0.00"
              aria-label="amountValue"
              aria-describedby="amount-value"
            />
          </div>
        </div>
      </div>
      <div className="d-flex mt-2">
        {
          !isTyping && <div className="ms-auto">
            <i className={`fa fa-2xs me-2 fa-arrow-right-arrow-left`} style={{ verticalAlign: '0.1em' }} />
            {
              solValue > 0 && <>{solValue} SOL</>
            }
          </div>
        }
        {
          isTyping &&
          <div className="w-100 ms-auto"> <span className="placeholder-glow">
            <span className="placeholder col-3 offset-9 h-1per"></span>
          </span>
          </div>
        }
      </div>

    </div>
  </div>)
}

/**
 * Main component for the first step in the Coin Flip Wizard.
 * Manages state for the side selection, bet amount, and displays messages.
 * 
 * @param {Object} props The component props.
 * @returns {JSX.Element} The rendered component for the first step of the Coin Flip Wizard.
 */
const CoinFlipWizardStepOneToken = ({
  side,
  setSide,
  tokenAmount,
  setTokenAmount,
  onDoubleOrNothing,
  style
}: any) => {
  const [value] = useDebounce(tokenAmount, 1000);
  const [solValue, setSolValue] = useState(0);
  const [isTyping, setIsTyping] = useState(false);

  const handleChangeAmount = async (e: any) => {
    if (e?.target?.value) {
      e.preventDefault(); // prevent the default action
      setTokenAmount(e.target.value); // set name to e.target.value (event)
    } else {
      setTokenAmount(e)
    }
    setIsTyping(true);
  };

  useEffect(() => {
    getSolValue(value);
  }, [value]);

  const getSolValue = async (value: any, retries = 3, delay = 1000) => {
    let attempt = 0;
    while (attempt < retries && !!value?.length) {
      try {
        const quote = await fetchQuote(+value);
        const outAmount = (+(quote?.outAmount as any)) / LAMPORTS_PER_SOL;
        setSolValue(customRound(outAmount - 0.001));
        setIsTyping(false);
        break; // If the request succeeds, exit the loop
      } catch (error) {
        attempt++;
        console.error(`Attempt ${attempt} failed: ${error}`);
        if (attempt < retries) {
          // Wait for a specified delay before retrying
          await new Promise(resolve => setTimeout(resolve, delay));
        } else {
          setIsTyping(false);
          throw error; // After all retries, throw the last error
        }
      }
    }
  };

  return (
    <>
      <h3 className="my-2 mt-sm-4">I LIKE</h3>
      <div className="row mb-1">
        <SideButton side={side} setSide={setSide} label="HEADS" value="H" />
        <SideButton side={side} setSide={setSide} label="TAILS" value="T" />
      </div>
      <hr />
      <AmountInputForm
        style={style}
        amount={tokenAmount}
        solValue={solValue}
        isTyping={isTyping}
        handleChangeAmount={handleChangeAmount}
      />
      {
        (!solValue || solValue >= 0.04) && <hr />
      }
      {
        solValue < 0.04 && <>
          <p className="text-danger p-1 my-2">Must flip equivalent to min 0.04 SOL.</p>
        </>
      }
      <button
        className="fs-4 btn btn-lg mb-1 w-100 font-buttons rounded-0 double-or-nothing-button letter-spacing-1"
        style={{ backgroundColor: WIF_COLOR }}
        onClick={() => onDoubleOrNothing(tokenAmount, MINTS.WIF)}
        disabled={!solValue || solValue < 0.04}>
        DOUBLE OR NOTHING
      </button>
    </>
  );
};

export default CoinFlipWizardStepOneToken;

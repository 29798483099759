import { useRef, useState } from "react";
import { CloseButton, Modal, OverlayTrigger, Popover } from "react-bootstrap";

export function TransactionPriorityModal({ priorityFee, styleCss, ...props }) {
  const inputRef = useRef(null);
  const [curr, setCurr] = useState(priorityFee);
  const [input, setInput] = useState();
  const [inputting, setInputting] = useState(false);
  const handleChangeInput = (e) => {
    e.preventDefault(); // prevent the default action
    const number = +e.target.value;
    setInput(number); // set name to e.target.value (event)
  }

  const handleOnClick = () => {
    setCurr(null);
    setInputting(true);
  }

  const handlePrioFeeClick = (amount) => {
    setCurr(amount);
    inputRef.current.value = "";
    setInput(null);
    setInputting(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={(styleCss.includes('dark') ? 'dark' : 'light') + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className="pt-4 px-4">
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">TRANSACTION PRIORITY</h5>
              <CloseButton variant="white" onClick={props.onHide} />
            </div>
          </div>
          <div className={"card-body px-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '620px', overflowY: 'scroll' }}>

            <div className="d-flex justify-content-between mt-3">
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                    <div className="card">
                      <div className="card-body">
                        The priority fee is paid to the Solana network. This additional fee helps boost how a transaction is prioritized against others, resulting in faster transaction execution times. </div>
                    </div>
                  </Popover>}
              >
                <div className="d-inline-flex">
                  <h6>SELECT&nbsp;TRANSACTION&nbsp;PRIORITY</h6>
                  <span className="ms-1 fas fa-circle fa-info-circle fa-2xs text-secondary"></span>
                </div>
              </OverlayTrigger>

              <hr className="w-100 mt-2" />
            </div>

            <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={curr === 0} onClick={() => handlePrioFeeClick(0)} />
              <label className={`btn btn-outline-${styleCss.includes('dark') ? 'light' : 'dark'} rounded-0`} for="btnradio1">MARKET<br /><small>MAX: 0.001 SOL</small></label>

              <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={curr === 0.005} onClick={() => handlePrioFeeClick(0.005)} />
              <label className={`btn btn-outline-${styleCss.includes('dark') ? 'light' : 'dark'} rounded-0`} for="btnradio3">HIGH<br /><small>MAX: 0.005 SOL</small></label>

              <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autocomplete="off" checked={curr === 0.01} onClick={() => handlePrioFeeClick(0.01)} />
              <label className={`btn btn-outline-${styleCss.includes('dark') ? 'light' : 'dark'} rounded-0`} for="btnradio4">TURBO<br /><small>MAX: 0.01 SOL</small></label>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <h6>OR&nbsp;SET&nbsp;MANUALLY&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <div class="input-group mb-1">
              <input type="number" step="0.00001"
                ref={inputRef}
                value={input}
                onChange={handleChangeInput}
                onClick={handleOnClick}
                class="form-control rounded-0"
                placeholder="0.0000"
                aria-label="priority fee"
                aria-describedby="prio-fee" />
              <span class="input-group-text rounded-0" id="basic-addon2">SOL</span>
            </div>
            {
              inputting &&
              <>
                {
                  input > 0 && <small className="text-secondary">
                    This will cost an additional {input} SOL.
                  </small>
                }
                {
                  input > 0.01 && <small className="text-warning">
                    <br />
                    <span className="ms-1 fas fa-circle fa-info-circle text-warning fa-xs"></span> Please set a priority fee within 0.01 SOL
                  </small>
                }
                {
                  input > 0.01 && <small className="text-warning">
                    <br />
                    <span className="ms-1 fas fa-circle fa-info-circle text-warning fa-xs"></span> Warning, max priority fee is over the suggested amount of 0.01 SOL.
                  </small>
                }
              </>
            }
          </div>
          <div className="card-footer px-4">
            <button
              disabled={(inputting && !input) || input > 1}
              className={`btn btn-block w-100 btn-lg mb-2 rounded-0 ${styleCss.includes('dark') ? 'btn-light' : 'btn-dark'}`}
              onClick={() => props.onSave(inputting ? input : curr)}>
              SAVE SETTINGS
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

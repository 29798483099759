import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { StyleThemeContext } from "../../contexts/style-theme.context";

export function ProModeModal({ ...props }) {
    const { style } = useContext(StyleThemeContext)
    const [enabled, setEnabled] = useState(false);
    setTimeout(function () {
        setEnabled(true);
    }, 3000);
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={style + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className={"card-body p-4" + (style === 'dark' ? ' pb-0' : '')}>
                        <h4 className="">⚠️ PROCEED WITH CAUTION!</h4>
                        Please read carefully.
                        <br />
                        <br />
                        Pro Mode is for high rollers with significant funds. Flipping is just for fun and It's crucial to only flip what you can afford to lose.
                        <br />
                        <br />
                        <p className="text-danger font-weight-bold mb-0">
                            By continuing, you acknowledge the risks and agree to play with care and responsibility.
                        </p>
                    </div>
                    <div className="card-footer">
                        <button
                            className={`btn btn-block w-100 btn-lg my-2 rounded-0 ${style.includes('dark') ? 'btn-light' : 'btn-dark'}`} disabled={!enabled}
                            onClick={props.onClose}>
                            I am a Responsible Degen
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
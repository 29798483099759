
import { Idl, Program, web3 } from '@project-serum/anchor';
import { getSolanaProvider, ProviderTypes } from "../services/solana";
import idl from '../interfaces/idl_dcf_23.json';
import moize from 'moize';
import { MINTS } from '../utils/constants';
import { executeClaimTx } from './jup.service';

const {
  PublicKey,
} = web3;

let programID: any;
let program: any;
let provider: any;

const init = moize((wallet: any = null) => {
  if (program) return;
  programID = new PublicKey(idl.metadata.address);
  provider = getSolanaProvider(wallet, ProviderTypes.PRIMARY);
  program = new Program(idl as Idl, programID, provider);
});

export const getTokenRewards = async (wallet: any, amount: any) => {
  init(wallet);
  const secondTx = await executeClaimTx(wallet, amount*2 - 0.001, MINTS.WIF)
  return secondTx;
};


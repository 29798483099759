import { BASED_COIN, BASED_COIN_INVERTED, constants } from "../../utils/constants"

// const DEFAULT_TOKEN_COIN = "https://i.imgur.com/AxYWlUB.png";
const WIF_TOKEN_COIN = "https://arweave.net/WI3PL7i6hjXsb-fLsoM1spR0lQNNnwmWNrxESKGM8Rw";

const CoinImage = ({ proMode, whaleMode, community, style, tokenMode }: any) => {
    if (proMode) {
        return <img
            className="mb-3 logo"
            src={style.includes('dark') ? BASED_COIN_INVERTED : BASED_COIN}
            alt="coin"
            width="128"
            height="128"
        />
    }
    if (whaleMode) {
        return (
            <img
                className="mb-3 logo"
                src={constants.LOGO.Whale}
                alt="coin"
                width="128"
                height="128"
            />)

    }
    if (tokenMode) {
        return (
            <img
                className="mb-3 logo rounded-circle"
                src={WIF_TOKEN_COIN}
                alt="coin"
                width="128"
                height="128"
            />)
    }
    return (
        <img
            className="mb-3 logo "
            src={community?.coinImageUrl}
            alt="coin"
            width="128"
            height="128"
        />)
}

export default CoinImage;

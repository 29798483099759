import { DateTime } from 'luxon';
import { createContext, useEffect, useState } from 'react';
import { queryLatestCoinFlips } from '../api/queries.service';

interface RecentCoinFlipsContextValue {
  recentCoinFlips: any[];
  fetch(): void;
}

const RecentCoinFlipsContext = createContext<RecentCoinFlipsContextValue>({
  recentCoinFlips: [],
  fetch() { }
});

const RecentCoinFlipsProvider = (props: any) => {
  const [queryParams] = useState({
    sortBy: '',
    timeFrame: 'today'
  });
  const [recentCoinFlips, setRecentCoinFlips] = useState([]);
  
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [])

  const fetch = async () => {
    const hourAgo = DateTime.utc().minus({ minutes: 30 });
    const recentFlips = await queryLatestCoinFlips(hourAgo, queryParams.sortBy, 100);
    setRecentCoinFlips(recentFlips);
  };

  return (
    <RecentCoinFlipsContext.Provider value={{ recentCoinFlips, fetch }}>
      {props.children}
    </RecentCoinFlipsContext.Provider>
  )
};

export { RecentCoinFlipsContext, RecentCoinFlipsProvider };

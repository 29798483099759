import { Toast, ToastContainer } from "react-bootstrap";
export const TokenSwapFailedToast = ({ show, onClose }: any) => {
  return (
    <ToastContainer position="bottom-start" className="p-3 position-fixed">
      <Toast className="" onClose={onClose} show={show} delay={10000} autohide>
        <Toast.Body>
          <div className="d-flex flex-row px-2 py-1">
            <div className="d-flex flex-column w-100">
              <h6 className="mb-0 d-flex">
                SWAP FAILED!
              </h6>
              <div className="text-gray mt-1">
                <small>Nothing has been deducted from your wallet.<br />Refresh browser if problem persists.</small>
              </div>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
import { useContext, useEffect, useState } from "react";
import { activateRiskFreePlay, deactivateRiskFreePlay } from "../api/aegis.service";
import { AuthorizationContext } from "../contexts/auth.context";
import { RiskFreePlaysStatus } from "../utils/constants";
import { RiskFreeFlipModal } from "./modals/RiskFreeFlipModal";

const getMaxSolAmountByStatus = (list: any, targetStatus: any) => {
    const itemsWithTargetStatus = list.filter((item: any) => item.status === targetStatus);

    if (itemsWithTargetStatus.length === 0) {
        return 0;
    }

    const maxSolAmount = Math.max(...itemsWithTargetStatus.map((item: any) => item.solAmount));
    return maxSolAmount;
};

const RiskFreeFlipCard = ({ style, maxActivated, maxAvailable, hasActivated, clickedOnCard, clickedOnToggle }: any) => {
    return (
        <div className={"rounded-3 px-4 py-3 mb-5 border shadow-sm cursor-pointer " + style + '-card'} onClick={clickedOnCard}>
            <div className="d-flex justify-content-between align-items-center"><div>
                {
                    hasActivated && <h6 className="h6 mb-0">
                        USING <u>{maxActivated} SOL</u> RISK FREE FLIP
                    </h6>
                }
                {
                    !hasActivated && <h6 className="h6 mb-0">
                        USE {maxAvailable} SOL RISK FREE FLIP
                    </h6>
                }
            </div>
                <div className="form-check form-switch form-check-md mb-0">
                    <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        id="checkPrivacy9"
                        checked={hasActivated}
                        onClick={clickedOnToggle}
                        onChange={(ev) => {
                            ev.preventDefault();
                        }}
                    />
                </div>
            </div>
        </div>
    )
};

export const RiskFreeFlipSection = ({ riskFreePlays, style }: any) => {
    const { auth } = useContext(AuthorizationContext);
    const [maxAvailable, setMaxAvailable] = useState(0);
    const [maxActivated, setMaxActivated] = useState(0);
    const [hasActivated, setHasActivated] = useState(false);


    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    useEffect(() => {
        const hasActivated = riskFreePlays?.filter((rff: any) => rff.status === RiskFreePlaysStatus.ACTIVATED)?.length > 0;
        setMaxAvailable(getMaxSolAmountByStatus(riskFreePlays, RiskFreePlaysStatus.AVAILABLE));
        setMaxActivated(getMaxSolAmountByStatus(riskFreePlays, RiskFreePlaysStatus.ACTIVATED));
        setHasActivated(hasActivated);
    }, [riskFreePlays]);

    const clickedOnCard = () => {
        handleModalOpen();
    }
    const clickedOnToggle = async (rff: any) => {
        // setLoading(true);
        if (rff.status === RiskFreePlaysStatus.AVAILABLE) {
            await activateRiskFreePlay(rff?.id, auth?.idToken);
        } else if (rff.status === RiskFreePlaysStatus.ACTIVATED) {
            await deactivateRiskFreePlay(rff?.id, auth?.idToken);
        }
        // await getRiskFreePlays(auth?.idToken);
        // setLoading(false);
    };

    return (
        <>
            <RiskFreeFlipCard
                style={style}
                maxAvailable={maxAvailable}
                maxActivated={maxActivated}
                hasActivated={hasActivated}
                clickedOnCard={clickedOnCard}
                clickedOnToggle={clickedOnToggle}
            />
            {
                showModal &&
                <RiskFreeFlipModal
                    show={showModal}
                    riskFreeFlips={riskFreePlays}
                    styleCss={style}
                    onHide={() => handleModalClose()}
                />
            }
        </>
    )
}

import { Idl, Program, BN, web3 } from '@project-serum/anchor';
import { getSolanaProvider, ProviderTypes } from "../services/solana";
import idl from '../interfaces/idl_dcf_23.json';
import { LAMPORTS_PER_SOL, SYSVAR_INSTRUCTIONS_PUBKEY, TransactionInstruction, TransactionMessage, VersionedTransaction, Transaction } from '@solana/web3.js';
import { getDegenCoinFlipDegenerateAccount, getDegenCoinFlipHouseState, getDegenCoinFlipHouseTreasury, getDegenCoinFlipRewardsAccount } from '../utils/accounts';
import { INITIALIZER_ID, AUTHORITY_ID, MEMO_ID, COLD_HOUSE_ID } from '../utils/program-constants';
import moize from 'moize';
import { ERRORS, MINTS } from '../utils/constants';
import { memoize, shouldVersionTransaction } from '../utils/helpers';
import { customRound } from '../components/side-bets/helpers';
import { fetchQuote, generateSwapAndFlipTx } from './jup.service';
import { initCoinFlip } from '../api/coin-flip.service';

const DEFAULT_PRIORITY_FEE = 0.0001;
const TRANSACTION_TOO_LARGE_ERROR_MESSAGE = "encoding overruns Uint8Array";

const {
  SystemProgram,
  PublicKey,
} = web3;

let programID: any;
let program: any;
let provider: any;

const init = moize((wallet: any = null) => {
  if (program) return;
  programID = new PublicKey(idl.metadata.address);
  provider = getSolanaProvider(wallet, ProviderTypes.PRIMARY);
  program = new Program(idl as Idl, programID, provider);
});

const generateMemoIx = async (coinFlip: any, tokenAmount: any, tokenId: any, solAmount: any, side: any, authToken: any) => {
  let initializedCoinFlip;
  try {
    initializedCoinFlip = await initCoinFlip({ ...coinFlip, amount: solAmount, tokenAmount, tokenId }, authToken);
  }
  catch (e) {
    console.log(e);
    throw ERRORS.DEPOSIT_FAILED;
  }

  const { id } = initializedCoinFlip;
  return {
    id,
    memoIx: new TransactionInstruction({
      keys: [{ pubkey: provider.wallet.publicKey, isSigner: true, isWritable: true }],
      data: memoize(id, solAmount, side),
      programId: MEMO_ID
    })
  }
};

const generateDepositIx = async (amount: any) => {
  const [_house_treasury_account_pda] = await getDegenCoinFlipHouseTreasury(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_house_state_account_pda] = await getDegenCoinFlipHouseState(
    INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_degenerate_account_pda] = await getDegenCoinFlipDegenerateAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  const [_rewards_account_pda] = await getDegenCoinFlipRewardsAccount(
    provider.wallet.publicKey, INITIALIZER_ID, AUTHORITY_ID, COLD_HOUSE_ID
  );

  return await program.instruction.participate(
    new BN(customRound(customRound(amount) * LAMPORTS_PER_SOL)),
    {
      accounts: {
        degenerate: provider.wallet.publicKey,
        initializer: INITIALIZER_ID,
        authority: AUTHORITY_ID,
        coldHouse: COLD_HOUSE_ID,
        houseTreasury: _house_treasury_account_pda,
        houseState: _house_state_account_pda,
        degenerateAccount: _degenerate_account_pda,
        rewardsAccount: _rewards_account_pda,
        systemProgram: SystemProgram.programId,
        instructions: SYSVAR_INSTRUCTIONS_PUBKEY
      }
    }
  );
}

export const depositToken = async (coinFlip: any, authToken: any, wallet: any, amount: any, side?: any, priorityFee = DEFAULT_PRIORITY_FEE) => {

  let retryCount = 0;

  while (retryCount < 5) {

    // generate quote, init, && generate blockhash
    let solAmount;
    let quote: any;
    try {
      quote = await fetchQuote(amount);
      solAmount = customRound((+quote?.outAmount / LAMPORTS_PER_SOL) - 0.001);
    } catch (e) {
      console.log(e);
      throw ERRORS.SWAP_QUOTE_FAILED;
    }

    init(wallet);

    // parallel
    // memo, swap, deposit
    const [memoIxPayload, swapIxesPayload, depositIx] = await Promise.all([
      generateMemoIx(coinFlip, amount, MINTS.WIF, solAmount, side, authToken),
      generateSwapAndFlipTx(wallet, quote, priorityFee),
      generateDepositIx(solAmount)
    ])

    const {
      id,
      memoIx
    } = memoIxPayload;

    const {
      swapIxes,
      addressLookupTableAccounts
    } = swapIxesPayload;

    // execute
    const { connection } = provider;
    const blockhash = (await connection.getLatestBlockhash()).blockhash;
    const messageV0 = new TransactionMessage({
      payerKey: wallet?.publicKey,
      recentBlockhash: blockhash,
      instructions: [
        memoIx,
        ...swapIxes,
        depositIx
      ],
    }).compileToV0Message(addressLookupTableAccounts);
    const versionedTransaction = new VersionedTransaction(messageV0);

    let tx;
    try {
      if (shouldVersionTransaction()) {
        const txes = await provider.sendAll([{ tx: versionedTransaction }]);
        tx = txes[0];
      } else {
        const serialized = versionedTransaction.serialize();
        const x = Transaction.from(serialized) as any;
        tx = await provider.send(x);
      }
      return { tx, id };
    }
    catch (e: any) {
      console.log(JSON.stringify(e));
      console.log(e);
      console.log({ e });
      const errorMessage = e.error.message;
      if (errorMessage !== TRANSACTION_TOO_LARGE_ERROR_MESSAGE) {
        throw ERRORS.DEPOSIT_FAILED;
      }
      if (errorMessage === TRANSACTION_TOO_LARGE_ERROR_MESSAGE) {
        retryCount++;
      }
    }
  }

  throw ERRORS.DEPOSIT_FAILED;
};


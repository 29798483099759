import { DateTime } from 'luxon';
import { createContext, useContext, useState } from 'react';
import { activateCoinOp, getCoinOp, getCoinOps, getCoinOpsByWallet } from '../api/coin-op.service';
import { CoinOpStatus, SideBetsGame } from '../utils/constants';
import { AuthorizationContext } from './auth.context';
import { confetti } from '../utils/confetti';
import { AudioContext } from './audio.context';
const confettiAnimation: any = confetti;

const playConfettiAnimation = (defaultTimeout = 1000, min: any = null, max: any = null) => {
  confettiAnimation?.start(defaultTimeout, min, max);
  setTimeout(function () {
    confettiAnimation?.stop()
  }, defaultTimeout);
};

interface SideBetsContextValue {
  sideBetsState: any;
  recentSideBets: any[];
  recentUserSideBets: any[];
  recentUserSideBetsLoading: boolean;
  loading: boolean;
  fetch(): void;
  fetchRecentSideBets(): void;
  fetchRecentUserSideBets(): void;
  setLatestSideBet(coinOp: any): void;
  setLoading(loading: boolean): void;
}

const SideBetsContext = createContext<SideBetsContextValue>({
  sideBetsState: null,
  recentSideBets: [],
  recentUserSideBets: [],
  recentUserSideBetsLoading: true,
  loading: true,
  fetch() { },
  fetchRecentSideBets() { },
  fetchRecentUserSideBets() { },
  setLatestSideBet() { },
  setLoading() { }
});

const SideBetsProvider = (props: any) => {
  const { auth } = useContext(AuthorizationContext);
  const { playBg } = useContext(AudioContext);

  const [state, setState] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [recentSideBets, setRecentSideBets] = useState([]);
  const [recentUserSideBets, setRecentUserSideBets] = useState([]);
  const [recentUserSideBetsLoading, setRecentUserSideBetsLoading] = useState(true);

  const handleUI = (coinOp: any, serialized: any = null, prevIsActive = false) => {
    if (coinOp?.status === CoinOpStatus.FINALIZED && serialized) {
      if (coinOp?.won) {
        playBg(70);
        playConfettiAnimation(1000, 100, 200);
      } else {
        console.log('fat loss');
      }
    }
    if (coinOp?.status === CoinOpStatus.FINALIZED && prevIsActive && !serialized) {
      playBg(73);
    }
  }

  const fetch = async (token = auth?.idToken) => {
    setLoading(true);
    const authToken = token ?? auth?.idToken;
    try {
      let { coinOp, serialized } = await getCoinOp(SideBetsGame.Slug, state?.coinOp?.id ?? '', authToken);
      if (coinOp?.status === CoinOpStatus.STARTED) {
        const response = await activateCoinOp(SideBetsGame.Slug, coinOp.id, authToken);
        coinOp = response.coinOp;
      }
      if (coinOp?.status === CoinOpStatus.ACTIVE) {
        playBg(72);
      }
      handleUI(coinOp, serialized, state?.isActive);
      setState({
        coinOp,
        serialized,
        isActive: coinOp.status === CoinOpStatus.ACTIVE,
        isPendingReward: ([CoinOpStatus.FINALIZED].includes(coinOp.status) && !!serialized) || ([CoinOpStatus.PROCESSING].includes(coinOp.status) && coinOp.won)
      });
    } catch (e) {
      console.log('no active sidebets');
    }

    setLoading(false);
  };

  const setLatestSideBet = (coinOp: any, serialized: any = null) => {
    handleUI(coinOp);
    setState({
      coinOp,
      isActive: coinOp?.status === CoinOpStatus.ACTIVE,
      isPendingReward: coinOp?.status === CoinOpStatus.FINALIZED
    });
  }

  const fetchRecentSideBets = async () => {
    const daysAgo = DateTime.utc().minus({ days: 30 }).toISO();
    const coinOps = await getCoinOps(daysAgo);
    setRecentSideBets(coinOps);
  };

  const fetchRecentUserSideBets = async () => {
    setRecentUserSideBetsLoading(true);
    const daysAgo = DateTime.utc().minus({ days: 365 }).toISO();
    const coinOps = await getCoinOpsByWallet(daysAgo, auth?.username);
    setRecentUserSideBets(coinOps);
    setRecentUserSideBetsLoading(false);
  };

  return (
    <SideBetsContext.Provider value={{
      sideBetsState: state,
      recentSideBets,
      recentUserSideBets,
      recentUserSideBetsLoading,
      loading,
      fetch,
      fetchRecentSideBets,
      fetchRecentUserSideBets,
      setLatestSideBet,
      setLoading
    }}>
      {props.children}
    </SideBetsContext.Provider>
  )
};

export { SideBetsContext, SideBetsProvider };

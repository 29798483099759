// import { DateTime } from "luxon";
import { CloseButton, Modal } from "react-bootstrap";
// import { EventTimes } from "../../utils/constants";
// import MarchCoinShop from '../../assets/images/2024MarchCoinShop.png';

// const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function AnnouncementModal({ styleCss, ...props }) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `fixthislater light-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className="pt-4 px-4">
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">WHAT'S NEW</h5>
              <CloseButton onClick={props.onHide} />
            </div>
            <small>May 1st, 2024</small>
          </div>
          <div className={"card-body px-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '620px', overflowY: 'scroll' }}>
            <img src="https://i.imgur.com/9vrnmpn.jpeg" alt="banner" className="img-fluid mb-1 rounded-3"></img>

            {/* <div className="rounded-3" style={{ width: '100%', overflow: 'hidden', maxHeight: '240px', position: 'relative' }}>
              <video className="rounded-3" style={{ width: '100%', height: '100%', transform: 'translate(0, -10%)' }} autoPlay playsInline>
                <source src="https://i.imgur.com/yANeMmt.mp4" type="video/mp4" />
              </video>
            </div> */}
            <div className="d-flex justify-content-between mt-3 text-success">
              <h6>WIF&nbsp;TOKEN&nbsp;FLIP&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>Double your WIF tokens today! Try your luck flipping the latest coins and tokens, starting with WIF.</li>
              {/* <li>Check to see if you have any WIF @ <a href="https://dogwifcoin.org" rel="noreferrer" target="_blank">https://dogwifcoin.org/</a></li> */}
            </ul>

            <div className="d-flex justify-content-between mt-3 text-warning">
              <h6>POINTS&nbsp;SYSTEM&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>Accumulate points as you play! Everlasting and ready for future DCF ecosystem surprises.</li>
            </ul>

            <div className="d-flex justify-content-between mt-3 text-danger">
              <h6>FUTURE&nbsp;RELEASES&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>We have new updates coming for DCF this year 2024. Stick around and find out. Something big coming. <a href="https://twitter.com/degencoinflip" rel="noreferrer" target="_blank">Twitter</a> & <a href="https://discord.gg/GnNacaEAyP" rel="noreferrer" target="_blank">Discord</a>.</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

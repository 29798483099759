import { constants } from "../../utils/constants";
import ImageToggleButton from "../ImageToggleButton";

const EMPTY_COIN_IMAGE_URL = "https://i.imgur.com/WtWzHUL.png";

const CoinFlipWizardVirtualStepOneButtons = ({
  side,
  setSide,
  onFlip
}: any) => {
  return (
    <>
      <img
        className="mb-3 logo"
        src={EMPTY_COIN_IMAGE_URL}
        alt="coin"
        width="128"
        height="128"
      />
      <h3 className="my-2 mt-sm-5">I LIKE</h3>
      <div className="row mb-1">
        <div className="col-6">
          <ImageToggleButton
            name="Heads"
            buttonImageSrc={constants.BUTTONS.Heads}
            isSelected={side === "H"}
            onSelect={() => setSide("H")}
          />
        </div>
        <div className="col-6">
          <ImageToggleButton
            name="Tails"
            buttonImageSrc={constants.BUTTONS.Tails}
            isSelected={side === "T"}
            onSelect={() => setSide("T")}
          />
        </div>
      </div>
      <hr />
      <img
        onClick={onFlip}
        className="cursor-pointer double-or-nothing-button mb-1"
        src={constants.BUTTONS.FlipACoin}
        alt="double or nothing"
        width="100%"
        height="100%"
      />
    </>
  );
};

export default CoinFlipWizardVirtualStepOneButtons;

import { DateTime } from "luxon";
import { Modal } from "react-bootstrap";
import { saveState } from "../../utils/localStorage";

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const START_TIME = "2023-09-23T17:00:00.000Z";
const END_TIME = "2023-09-30T17:00:00.000Z";
// const RAFFLE_TICKET = "https://i.imgur.com/NSU0AQo.png";
const RAFFLE_TICKET = "https://i.imgur.com/6xV5atn.png";

const imageStyle = {
  width: '86px',
  // height: '86px' 
};

const PRIZES = [
  // {
  //   name: "Tensorians",
  //   images: [
  //     "https://prod-tensor-creators-s3.s3.us-east-1.amazonaws.com/drop-metadata/44f27171-430f-42ca-b442-6a75a8daadad/images/8614.png",
  //     "https://prod-tensor-creators-s3.s3.us-east-1.amazonaws.com/drop-metadata/24f27171-430f-42ca-b442-6a75a8daadad/images/3721.png",
  //     "https://prod-tensor-creators-s3.s3.us-east-1.amazonaws.com/drop-metadata/44f27171-430f-42ca-b442-6a75a8daadad/images/9919.png",
  //     "https://prod-tensor-creators-s3.s3.us-east-1.amazonaws.com/drop-metadata/14f27171-430f-42ca-b442-6a75a8daadad/images/762.png",
  //     "https://prod-tensor-creators-s3.s3.us-east-1.amazonaws.com/drop-metadata/44f27171-430f-42ca-b442-6a75a8daadad/images/8614.png"
  //   ],
  //   url: "https://www.tensor.trade/trade/tensorians",
  //   totalWinners: 25,
  // },
];

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
};

export function JackpotModal({ walletId, styleCss, modalKey, startTime, endTime, ...props }) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `fixthislater light-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '484px', overflow: 'scroll' }}>
            <h4>Beach Rescue Event (400 SOL)</h4>
            <img src="https://i.imgur.com/RFxxW1Q.png" alt="banner" className="img-fluid mb-1 rounded-3"></img>
            <h6 className="text-center mt-2">Sept 23rd - Sept 30th</h6>
            <hr className="mt-2" />
            <p>
              Swimming against the currents can be tough. But our lifeguards have your floaty! Every loss on any of our games will give you floaties to enter you in our raffle for the week.
              <br />
              <br />
              Starts {DateTime.fromISO(START_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)} and winners will be picked on {DateTime.fromISO(END_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)}.
            </p>
            <hr />
            <img src="https://i.imgur.com/R20PIPw.png" alt="banner" className="img-fluid mb-1 rounded-3"></img>
            <hr />
            {PRIZES.map(prizeData => (
              <>
                <h5 className="text-center mb-4">
                  <small>{prizeData.totalWinners} Winner{prizeData.totalWinners > 1 ? 's' : ''}:</small><br />
                  <small>{prizeData.totalWinners}x {prizeData.name}</small><br />
                  <div className="d-flex justify-content-center" >
                    {
                      prizeData.images.map((image, i) => (
                        <div className="d-flex align-items-center justify-content-center bg-light rounded-sm" style={i === 0 ? imageStyle : { ...imageStyle, marginLeft: '-0.5rem' }}>
                          <img src={image} className="rounded-sm border border-2 border-light cursor-pointer button-fx" onClick={() => openInNewTab(prizeData.url)} width={86} alt="Avatar" />
                        </div>
                      ))
                    }
                  </div>
                  <small className="mt-2">(1 ea)</small>
                </h5>
                <hr />
              </>
            ))}
            <u>To Enter</u>: <br />
            <small>
              Automatically collect raffle tickets <img src={RAFFLE_TICKET} className="img-fluid mb-1" alt="raffle" style={{ maxWidth: '32px' }} /> and enter after bad lose streaks.
            </small>
            {/* <small>
              Automatically collect raffle tickets <img src={RAFFLE_TICKET} className="img-fluid mb-1" alt="raffle" style={{ maxWidth: '32px' }} /> and enter when you hit 3, 5, 7, and 10 streaks.
            </small> */}
            <br />
            <br />
            <u>How many tickets do I earn per flip loss?</u><br />
            <small>
              Higher Sol Flipped = More tickets.
            </small>
            <br /><br />
            {/* <u>How do I check How many Entries I have?</u><br />
            <small>
              You will be able to use !entries (wallet) in our Discord channel #bot-commands to see how many entries you have.
            </small>
            <br /><br /> */}
            <u>When does this start/end?</u><br />
            <small>
              Plays from {DateTime.fromISO(START_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)} and 30 minutes before we draw our winners live on stream after {DateTime.fromISO(END_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)}.
            </small>
            <br />
            <br />
            <u>Earn More</u>: <br />
            <small>
              <a href={`https://degencoindozer.com`} target="_blank" rel="noopener noreferrer">
                degencoindozer.com</a>
              <br />
              <a href={`https://degenspin.com`} target="_blank" rel="noopener noreferrer">
                degenspin.com</a>
            </small>
          </div>
          <div className="card-footer">
            <button
              className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
              onClick={() => { saveState(true, modalKey); props.onHide() }}>
              OKAY
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import { DateTime } from 'luxon';
import { createContext, useState } from 'react';
import { signMessage } from '../api-smart-contracts/dcf';
import { authorize, authorizeViaTx, getNonce } from '../api/degen.service';
import { authTransfer } from '../services/auth-transfer.service';
import { UserAccessStatus } from '../utils/constants';
import { loadState, saveState } from '../utils/localStorage';

interface Authorization {
  username: string | any;
  idToken: string;
  exp: number;
  status: string;
}

interface AuthorizationContextValue {
  auth: Authorization | any,
  signIn(walletId: string, referral: string): void;
  signInViaLedger(wallet: any): void;
  signOut(): void;
  signNonce(): any;
  enableVerified(): any;
  isExpired: any;
}

const AuthorizationContext = createContext<AuthorizationContextValue>({
  auth: null,
  signIn() { },
  signInViaLedger() { },
  signOut() { },
  signNonce() { },
  isExpired() { },
  enableVerified() { }
});

const AuthorizationProvider = (props: any) => {
  const [auth, setAuth] = useState<Authorization | any>(loadState());

  const handleSaveAuth = (data: Authorization | any) => {
    saveState(data);
    setAuth(data);
  };

  const signIn = async (wallet: any, referral: string) => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== wallet?.publicKey?.toString()) {
      const { nonce } = await getNonce(wallet?.publicKey?.toString(), referral);
      const message = `I am signing my one-time nonce: ${nonce}`;
      const signature = await wallet.signMessage(new TextEncoder().encode(message));
      const authorization = await authorize(wallet?.publicKey?.toString(), Buffer.from(signature).toString('base64'));
      saveState(false, 'ledgerEnabled');
      handleSaveAuth(authorization)
      return authorization;
    }

    return auth;
  };

  const signInViaLedger = async (wallet: any) => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== wallet?.publicKey?.toString()) {
      const tx = await authTransfer(wallet);
      const authorization = await authorizeViaTx(wallet?.publicKey?.toString(), tx);
      saveState(true, 'ledgerEnabled');
      handleSaveAuth(authorization)
      return authorization;
    }

    return auth;
  };

  const signOut = () => handleSaveAuth(null);
  const signNonce = async () => {
    const walletId = auth?.username;
    const { nonce } = await getNonce(walletId, '');
    const { signature } = await signMessage(nonce);
    return signature;
  }
  const isExpired = () => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    return !auth || expired;
  }
  const enableVerified = () => {
    handleSaveAuth({ ...auth, status: UserAccessStatus.VERIFIED });
  }
  return (
    <div>
      <AuthorizationContext.Provider value={{ auth, signIn, signInViaLedger, signOut, signNonce, isExpired, enableVerified }}>
        {props.children}
      </AuthorizationContext.Provider>
    </div>
  )
};

export { AuthorizationContext, AuthorizationProvider };

import React, { useContext, useEffect, useState } from "react"
import RangeSlider from 'react-bootstrap-range-slider';
import { Form } from "react-bootstrap";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { useInterval } from "../../hooks/useInterval";
import { confetti } from '../../utils/confetti';

import './legendary-raffle.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { AudioContext } from "../../contexts/audio.context";
import { EntryCheckerModal } from "../../components/modals/EntryCheckerModal";
import { useWallet } from "@solana/wallet-adapter-react";
import { getLegendaryRaffleEntries } from "../../api/pusher-api.service";
import { getProfile } from "../../api/profiles.service";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
};

function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const ProfilePicture = ({ imageUrl, nickname, defaultProfilePicture = `https://i.imgur.com/WtWzHUL.png` }) => {
  return (
    <>
      <div className="profile-picture">
        <img className='image rounded-circle'
          src={imageUrl ?? defaultProfilePicture}
          alt={'PFP'}
          onError={(e) => {
            if (e.target.src !== defaultProfilePicture) { e.target.onerror = null; e.target.src = defaultProfilePicture; }
          }} />
      </div>
      <h6 className="title mb-auto ms-2">{nickname}</h6>
    </>
  )
}

const buildData = async () => {
  const {
    top,
    top10,
    allEntries,
    total
  } = await getLegendaryRaffleEntries();
  return {
    top,
    top10,
    allEntries,
    total
  };
  // const keys = Object.keys(entries);
  // const allNormalEntries = [].concat(...keys.map(walletId => Array(entries[walletId].normal).fill(walletId)));
  // const allWhaleEntries = [].concat(...keys.map(walletId => Array(entries[walletId].whale).fill(walletId)));
  // return {
  //   allNormalEntries,
  //   allWhaleEntries,
  //   distinctNormalEntries: Object.keys(entries).map(e => e).filter(walletId => entries[walletId].normal > 0),
  //   distinctWhaleEntries: Object.keys(entries).map(e => e).filter(walletId => entries[walletId].whale > 0),
  //   data: entries
  // };
};

const IntervalText = ({ entries, top, top10, current, onDraw }) => {
  const [displayedText, setDisplayedText] = useState();
  const [i, setI] = useState(0);
  const [interval, setInterval] = useState(50);
  useInterval(async () => {
    let list = [];
    if (current === 0) list = entries;
    else if (current === 1) list = top10;
    else if (current === 2) list = top;
    setDisplayedText(list[i]);
    const r = randomNumber(0, list.length);
    setI(r);
  }, interval);

  const draw = () => {
    if (current === 0) onDraw(entries, 'Kitsune');
    else if (current === 1) onDraw(top10, 'Crimson');
    else if (current === 2) onDraw(top, 'Kaido');
  }
  return (<>
    <div className="row">
      <div className="col-6 offset-3">
        <button className="btn btn-warning btn-lg px-4 gap-3" onClick={() => draw()}>DRAW WINNER</button>
      </div>
      {/* <div className="col-6">
        <button className="btn btn-primary btn-lg px-4 gap-3" onClick={() => onDraw(true)}>DRAW WHALE</button>
      </div> */}
    </div>
    <Form>
      <Form.Group>
        <RangeSlider
          value={interval}
          onChange={e => setInterval(e.target.value)}
        />
      </Form.Group>
    </Form>
    <span id="cycle">{displayedText}</span>

  </>
  )
}


const ENTRIES = [
  "ALL DOZER PLAYERS",
  "TOP 10% DOZER PLAYERS",
  "TOP 10 DOZER PLAYERS"
];

const LEGENDARY_NAMES = [
  "Kitsune, The Last Flame",
  "Crimson, The Lonely Blade",
  "Kaido, The Emperor"
];

const LEGENDARY_LINKES = [
  "https://magiceden.io/item-details/CbkRHMdXsnQsMtUFSFmTkcM3B3KpP51xVLDXSNCRnkLU",
  "https://magiceden.io/item-details/J7aqPRpELJkjX173vQyBayDSDu4vgugK8wb9b5J5VfhW",
  "https://magiceden.io/item-details/5HLFqQahEwogx9M3cEqrEteGxp7SdH4LXWiLhjm8R7sa"
];

const LEGENDARIES = [
  "https://i.imgur.com/GquWZyb.png",
  "https://i.imgur.com/ZWi3HWM.png",
  "https://i.imgur.com/hhR7ELk.png"
];

export const LegendaryRaffleGiveaway = () => {
  const wallet = useWallet();
  const [current, setCurrent] = useState(0);
  const { style } = useContext(StyleThemeContext);
  const [selected, setSelected] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { play } = useContext(AudioContext);
  const [isLoading, setIsLoading] = useState(null);
  const [history, setHistory] = useState([]);
  const [allEntries, setAllEntries] = useState([]);
  const [top, setTop] = useState([]);
  const [top10, setTop10] = useState([]);
  // const [total, setTotal] = useState();


  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const {
      top,
      top10,
      allEntries,
      // total
    } = await buildData();
    console.log(allEntries);
    setAllEntries(allEntries);
    setTop(top);
    setTop10(top10);
    // setTotal(total);
  }

  const onDraw = async (wallets, prefix) => {
    const confettiAnimation = confetti;
    const playConfettiAnimation = (defaultTimeout = 5000, min, max) => {
      confettiAnimation?.start(defaultTimeout, min, max);
      setTimeout(function () {
        confettiAnimation?.stop()
      }, defaultTimeout);
    };

    // if (isWhale) {
    //   var walletId = allWhaleEntries[Math.floor(Math.random() * allWhaleEntries.length)];
    //   setIsLoading(true);
    //   setTimeout(function () {
    //     play(13);
    //   }, 3800);
    //   setTimeout(function () {
    //     setIsLoading(false);
    //     playConfettiAnimation(10000, 420, 690);
    //     const whaleHistories = [walletId, ...whaleHistory];
    //     setWhaleHistory(whaleHistories);
    //     setSelected(walletId);
    //   }, 4200);

    // }
    // else {
    setSelectedUser(null);
    setSelected(null);

    var wallet = wallets[Math.floor(Math.random() * wallets.length)];
    setIsLoading(true);
    setTimeout(function () {
      play(13);
    }, 3800);
    setTimeout(async function () {
      setIsLoading(false);
      playConfettiAnimation(10000, 420, 690);
      const newHistory = [`${prefix}: ${wallet}`, ...history];
      console.log(newHistory);
      setHistory(newHistory);
      setSelected(wallet);
      try {
        const profile = await getProfile(wallet);
        console.log(profile);
        setSelectedUser(profile);
      } catch (e) {
        console.log(e);
      }
    }, 4200);
    // }

  }


  const [showJackpotModal, setShowJackpotModal] = useState(false);
  const handleJackpotModalOpen = () => setShowJackpotModal(true);
  const handleJackpotModalClose = () => setShowJackpotModal(false);

  return (
    <React.Fragment>
      <div className={style}>
        <div className="text-center d-flex main-header h-100vh-desktop">
          <div className="play step2">
            <h2>{LEGENDARY_NAMES[current]}</h2>
            <div className="pt-4 d-flex">
              <img onClick={() => openInNewTab(LEGENDARY_LINKES[current])} src={LEGENDARIES[current]} className="img-fluid mx-auto rounded border shadow double-or-nothing-button cursor-pointer" style={{ height: '256px' }} alt="..." />
            </div>
            <a href="#!" onClick={ev => { ev.preventDefault(); setCurrent(current === LEGENDARIES?.length - 1 ? 0 : current + 1); }} ><h6 className="mt-3">NEXT</h6></a>
            {
              !isLoading &&
              <div className="shake-it">
                <br />
                {
                  selectedUser != null &&
                  <>
                    <ProfilePicture imageUrl={selectedUser?.profileImageUrl} nickname={selectedUser?.nickname ?? selected} />
                  </>
                }
                <h1 className="fw-bold blink_me">{selected}</h1>
              </div>
            }
            <div className={"form-signin" + (isLoading ? " shake-it" : "")}>
              {
                allEntries?.length > 0 &&
                <IntervalText entries={allEntries} top={top} top10={top10} current={current} onDraw={onDraw}></IntervalText>
              }
              <a href="#!" onClick={ev => { ev.preventDefault(); handleJackpotModalOpen();}}><h6 className="mt-3">SEE {ENTRIES[current]} ENTRIES</h6></a>
              <br />
              <br />
              ____
              <br />
              <br />
              <br />
            </div>
            <div className="row">
              <div className={"col-6 offset-3 " + style + '-color'}>
                <div className={"card " + style + '-color'}>
                  <div className={"card-body " + style + '-color'}>
                    <h3>WINNERS</h3>
                    {
                      history?.map(a => {
                        return <h6>{a}</h6>
                      })
                    }
                  </div>
                </div>
              </div>
              {/* <div className="col-6">
                <div className="card">
                  <div className="card-body">
                    <h3>🐳 WINNER</h3>
                    {
                      whaleHistory?.map(a => {
                        return <h6>{a}</h6>
                      })
                    }
                  </div>
                </div>
              </div> */}
            </div>
            {isLoading &&
              <div id="demo"></div>
            }
          </div>
        </div>
      </div>
      {
        showJackpotModal &&
        <EntryCheckerModal
          wallet={wallet}
          entries={allEntries} top={top} top10={top10} current={current}
          image={LEGENDARIES[current]}
          show={showJackpotModal}
          styleCss={style}
          onClose={() => handleJackpotModalClose()}
        />
      }
    </React.Fragment>
  );
};